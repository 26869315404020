import Box from "@mui/material/Box";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

interface AppRadioButtonProps {
  firstLabel: string;
  secondLabel: string | number;
}

const AppRadioButton: React.FC<AppRadioButtonProps> = ({
  firstLabel,
  secondLabel,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const handleRadioClick = (value: string) => {
    setSelectedValue((prevValue) => (prevValue === value ? null : value));
  };

  const formatNumber = (value: number): string => {
    if (value >= 1000) {
      return value.toLocaleString();
    }
    return value.toString();
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "#FFFFFF",
      }}
      mb={1}
    >
      {/* <Radio
        sx={{
          color: "#514C57",
          "&.Mui-checked": {
            color: "#BB1BEF",
          },
        }}
        checked={selectedValue === secondLabel}
        onClick={() => handleRadioClick(secondLabel.toString())}
        value={secondLabel}
      /> */}
      <ArrowRightIcon />
      <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
        {firstLabel} -{" "}
        <Typography component="span" sx={{ fontSize: "12px", fontWeight: 700 }}>
          {typeof secondLabel === 'number' ? formatNumber(secondLabel) : secondLabel}
        </Typography>
      </Typography>
    </Box>
  );
};

export default AppRadioButton;
