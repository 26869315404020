import { Box, CircularProgress } from "@mui/material";

const AppLoader: React.FC = () => {
  return (
    <Box display='flex' width='100%' justifyContent='center' mt='20%'>
        <CircularProgress size={119} sx={{color:'#D522FD'}}/>
    </Box>
  );
};

export default AppLoader;
