import React, { useState } from "react";
import { Grid } from "@mui/material";
import Navbar from "./components/Navbar";
import Preview from "./components/Preview";
import PassengerProfile from "./components/PassengerProfile";
import { StepProvider } from "./context/StepContext";
import { LoadingProvider } from "./context/LoadingContext";
import { PassengerProvider } from "./context/PassengerContext";

const App: React.FC = () => {
  const [passengerData, setPassengerData] = useState({});
  const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

  return (
    <StepProvider>
      <PassengerProvider>
        <LoadingProvider>
          <Navbar />
          <Grid container>
            <Grid item xs={12} lg={6} sm={12} md={12}>
            <PassengerProfile
              isPreviewLoaded={isPreviewLoaded}
              setIsPreviewLoaded={setIsPreviewLoaded}
              passengerData={passengerData}
              setPassengerData={setPassengerData}
            />
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={12}>
            <Preview isPreviewLoaded={isPreviewLoaded}
              setIsPreviewLoaded={setIsPreviewLoaded}
            />
            </Grid>
          </Grid>
        </LoadingProvider>
      </PassengerProvider>
    </StepProvider>
  );
};

export default App;
