import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { PassengerProfile } from "../types/Passenger";
import { ancillaryImagesEmails } from "../constants/Constants";

interface OrderConfirmationEmailProps {
    passengerProfile: PassengerProfile,
    selectedAncillaries: string[],
    formatNumber: (num: number) => string,
    setEmailHTML: (value: (((prevState: string) => string) | string)) => void,
    emailHTML?: string,
    onLogHTML: () => void
}

export interface OrderConfirmationEmailRef {
    logHTML: () => void;
}

const OrderConfirmationEmail = forwardRef<OrderConfirmationEmailRef, OrderConfirmationEmailProps>(({
    passengerProfile,
    selectedAncillaries,
    formatNumber,
    setEmailHTML,
    emailHTML,
    onLogHTML
}, ref) => {
    const renderBalanceBox = (title: string, amount: number) => (
        `<td style="padding: 10px; border-right: 1px solid #212121; text-align: left;">
            <div style="font-size: 11px; font-weight: normal; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${title}</div>
            <div style="font-size: 19px; font-weight: bold;">${formatNumber(amount)} <span style="font-size: 11px;">Miles</span></div>
        </td>`
    );

    const renderAncillary = (item: any) => (
        `<td style="padding: 10px; text-align: center;">
            <div style="width: 180px; margin: 0 auto;">
                <div style="width: 180px; height: 180px; overflow: hidden;">
                    <img src="${ancillaryImagesEmails[item.name]}" alt="${item.name}" style="width: 180px; height: 180px; object-fit: cover; display: block; border: 0;" width="180" height="180">
                </div>
                <div style="font-size: 12px; font-weight: bold; margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 180px; text-align: center;">${item.name}</div>
                <div style="font-size: 11px;">${formatNumber(item.price)} Miles</div>
            </div>
        </td>`
    );

    const renderAncillariesTable = (ancillaries: any[]) => {
        const rows = [];
        for (let i = 0; i < ancillaries.length; i += 3) {
            const row = ancillaries.slice(i, i + 3);
            rows.push(`
                <tr>
                    ${row.map(renderAncillary).join('')}
                    ${row.length < 3 ? '<td></td>'.repeat(3 - row.length) : ''}
                </tr>
            `);
        }
        return `
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="table-layout: fixed;">
                <tr>
                    <td style="text-align: center;">
                        <table cellpadding="0" cellspacing="0" border="0" style="display: inline-block; text-align: center;">
                            ${rows.join('')}
                        </table>
                    </td>
                </tr>
            </table>
        `;
    };

    const generateEmailContent = useCallback((display: string) => `
        <div style="width: 100%; max-width: 600px; margin: 0 auto; ${display}">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="font-family: Arial, sans-serif; color: #181818;">
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" border="0" width="100%" style="background-color: #ffffff; box-shadow: 0px 1px 4px 0px #00000040;">
                            <tr>
                                <td style="padding: 10px 20px; font-size: 12px; width: 33%;">
                                    <span style="font-weight: normal;">Etihad Guest No.:</span>
                                    <span style="font-weight: bold;">${passengerProfile?.recentFlight?.loyaltyNumber}</span>
                                </td>
                                <td style="width: 34%; padding: 10px 0;">
                                    <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                        <tr>
                                            <td align="center">
                                                <img src="https://i.ibb.co/G3cY6RZ/airline-Logo.png" alt="airlineLogo" style="display: block; margin: 0 auto;" width="54" height="27">
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="padding: 10px 20px; text-align: right; width: 33%;">
                                    <div style="font-size: 12px; font-weight: normal;">Remaining Balance</div>
                                    <div style="font-size: 13px; font-weight: bold;">
                                        ${formatNumber((passengerProfile.milesEarnt || 0) +
                                            (passengerProfile.milesAccountBalance || 0) -
                                            passengerProfile.selectedAncillaries.reduce((acc, item) => acc + (item.price || 0), 0))} Miles
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="padding: 20px;">
                        <h2 style="font-size: 20px; font-weight: normal; color: #181818; margin-bottom: 20px; text-align: center;">
                            Order Confirmation
                        </h2>
                        <p style="font-size: 14px; font-weight: normal; color: #181818; margin-bottom: 20px; text-align: center;">
                            Your ancillaries have now been confirmed. Redeem selected ancillaries during your next flight with us.
                        </p>
                        ${renderAncillariesTable(passengerProfile.selectedAncillaries)}
                        <table cellpadding="0" cellspacing="0" border="0" width="100%" style="margin-top: 20px; border: 1px solid #212121; border-radius: 10px;">
                            <tr>
                                ${renderBalanceBox("Starting Balance", (passengerProfile.milesEarnt || 0) + (passengerProfile.milesAccountBalance || 0))}
                                ${renderBalanceBox("Purchase Amount", passengerProfile.selectedAncillaries.reduce((acc, item) => acc + (item.price || 0), 0))}
                                ${renderBalanceBox("Remaining Balance",
                                    (passengerProfile.milesEarnt || 0) +
                                    (passengerProfile.milesAccountBalance || 0) -
                                    passengerProfile.selectedAncillaries.reduce((acc, item) => acc + (item.price || 0), 0)
                                )}
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    `, [passengerProfile, selectedAncillaries, formatNumber]);

    const logHTML = useCallback(() => {
        const emailContent = generateEmailContent('display: block;');
        const fullHTML = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Order Confirmation</title>
            </head>
            <body style="margin: 0; padding: 0;">
                ${emailContent}
            </body>
            </html>
        `;
        console.log(fullHTML);
        const escapedHTML = fullHTML.replace(/\n/g, "");
        setEmailHTML(escapedHTML);
        onLogHTML();
    }, [passengerProfile, selectedAncillaries, generateEmailContent, setEmailHTML, onLogHTML]);

    useImperativeHandle(ref, () => ({
        logHTML
    }));

    return (
        <>
            <div dangerouslySetInnerHTML={{__html: generateEmailContent('display: none;')}}/>
        </>
    );
});

export default OrderConfirmationEmail;