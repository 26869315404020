import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    InputBase,
    Tooltip,
    Typography,
    Grid
} from "@mui/material";
import { Field, Formik } from "formik";
import { Ancillary } from "../types/Ancillary";
import Airline from "./Airline";
import { usePassenger } from "../context/PassengerContext";
import { useStep } from "../context/StepContext";
import { validationPurchaseSchema } from "../utils/validation";
import OrderConfirmationEmail, { OrderConfirmationEmailRef } from "./OrderConfirmationEmail";

interface AirlineListProps {
    ancillaries: Ancillary[],
    toggleAncillary: (ancillaryName: string, value: Ancillary) => void,
    selectedAncillaries: string[],
    isConfirm?: boolean,
    setIsConfirm: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const AirlineList: React.FC<AirlineListProps> = ({
     ancillaries,
     toggleAncillary,
     selectedAncillaries,
     isConfirm,
     setIsConfirm
 }) => {
    const { setStep } = useStep();
    const { passengerProfile, setPassengerProfile } = usePassenger();
    const [userEmail, setUserEmail] = useState("");
    const [emailHTML, setEmailHTML] = useState('');
    const orderConfirmationEmailRef = useRef<OrderConfirmationEmailRef>(null);

    const formatNumber = (num: number): string => {
        return num.toLocaleString();
    };

    const canSelectAncillary = (ancillary: Ancillary) => {
        const currentTotalPrice = (passengerProfile.selectedAncillaries || []).reduce(
            (total, selectedAncillary) => total + selectedAncillary.price,
            0
        );
        const newTotalPrice = currentTotalPrice + ancillary.price;
        return newTotalPrice <= (passengerProfile.milesAccountBalance || 0) + (passengerProfile.milesEarnt || 0);
    };

    const sendRecommendationEmail = useCallback(async () => {
        const selectedAncillaryPrices = ancillaries
          .filter(ancillary => selectedAncillaries.includes(ancillary.name))
          .reduce((acc, ancillary) => {
            acc[ancillary.name] = ancillary.price;
            return acc;
          }, {} as Record<string, number>);

        const requestBody = {
          recipient_email: userEmail,
          ancillaries: selectedAncillaryPrices,
          html_content: emailHTML
        };

        console.log('Request body:', JSON.stringify(requestBody, null, 2));

        try {
          const response = await fetch(
            "https://nrt09zgdoi.execute-api.eu-west-1.amazonaws.com/ProtoAPI/sendRecommendationEmail",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log("Email sent successfully:", data);
        } catch (error) {
          console.error("Error sending email:", error);
        }
      }, [userEmail, emailHTML, ancillaries, selectedAncillaries]);

      const handleCompletePurchase = useCallback(() => {
        if (orderConfirmationEmailRef.current) {
          orderConfirmationEmailRef.current.logHTML();
        }
      }, [orderConfirmationEmailRef]);

      useEffect(() => {
        if (emailHTML) {
          sendRecommendationEmail();
          setStep("complete");
          setPassengerProfile((prevProfile) => ({
            ...prevProfile,
            selectedAncillaries: [],
          }));
        }
      }, [emailHTML, sendRecommendationEmail, setStep, setPassengerProfile]);


    const renderHeader = () => (
        <Box
            sx={{
                height: "59px",
                minHeight: "59px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0px 1px 4px 0px #00000040",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    pl: "20px",
                    maxWidth: "calc(50% - 27px)",
                    overflow: "hidden",
                }}
            >
                <Typography sx={{fontSize: "10px", fontWeight: 400, whiteSpace: "nowrap"}}>
                    Etihad Guest No.:
                </Typography>
                <Typography sx={{fontSize: "10px", fontWeight: 700, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>
                    {passengerProfile?.recentFlight?.loyaltyNumber}
                </Typography>
            </Box>

            <img
                src={require("../assets/images/airlineLogo.png")}
                alt="Logo"
                style={{
                    display: "flex",
                    width: 54,
                    height: 27,
                    objectFit: "contain",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                }}
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "98px",
                    height: "43px",
                    borderRadius: "5px",
                    borderStyle: "solid",
                    borderWidth: "1.18px",
                    borderColor: "#DCDBDB",
                    cursor: "pointer",
                    alignItems: "flex-end",
                    mr: "23px",
                    pr: "6.55px",
                    pt: "2px",
                }}
            >
                <Typography sx={{fontSize: "12px", fontWeight: 400}}>
                    Total Balance
                </Typography>
                <Typography sx={{fontSize: "13px", fontWeight: 600}}>
                    {formatNumber((Number(passengerProfile.milesEarnt) || 0) +
                        (Number(passengerProfile.milesAccountBalance) || 0))}{" "}
                    Miles
                </Typography>
            </Box>
        </Box>
    );

    const renderBalanceBox = (title: string, amount: number) => (
        <Box
            sx={{
                borderRightStyle: {xs: "none", md: "solid"},
                borderRightWidth: {xs: "0px", md: "0px"},
                borderColor: "#212121",
                width: {xs: "100%", md: "auto"},
                mb: {xs: "10px", md: "0px"},
            }}
        >
            <Typography sx={{fontSize: "11px", fontWeight: 400, whiteSpace: 'nowrap'}}>
                {title}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "8px",
                    justifyContent: {xs: "center", md: "flex-start"},
                }}
            >
                <Typography sx={{fontSize: "19px", fontWeight: 700}}>
                    {formatNumber(amount)}
                </Typography>
                <Typography sx={{fontSize: "11px"}}>Miles</Typography>
            </Box>
        </Box>
    );

    const renderConfirmation = ({values, setFieldValue, errors, handleSubmit}: any) => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                px: {xs: "8px", lg: "24px"},
                pb: '24px'
            }}
        >
            <Typography
                sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    color: "#181818",
                    mt: "27px",
                }}
            >
                Order Confirmation
            </Typography>

            <Typography
                sx={{
                    fontSize: "14px",
                    fontWeight: 350,
                    color: "#181818",
                    mt: "13px",
                    mb: 1
                }}
            >
                You will now receive an email with the benefit confirmation
            </Typography>

            <Grid container spacing={2} justifyContent="center">
                {passengerProfile.selectedAncillaries.map((item, index) => (
                    <Grid item lg={4} md={4} sm={6} xs={8}  key={item.name + index}>
                        <Airline
                            disabled={true}
                            value={item}
                            imageSrc={item.imageUrl}
                            title={item.name}
                            miles={formatNumber(item.price)}
                            selectedAncillaries={selectedAncillaries}
                            toggleAncillary={toggleAncillary}
                            size="small"
                        />
                    </Grid>
                ))}
            </Grid>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: {lg: "60px"},
                    gap: {xs: "10px", md: "16px", lg: "22px"},
                    pl: {xs: "12px", md: "20px", lg: "27px"},
                    pr: {xs: "12px", md: "20px", lg: "27px"},
                    border: "1px solid",
                    borderColor: "#212121",
                    borderRadius: "10px",
                    width: {xs: "100%", sm: "90%", md: "80%", lg: "490px"},
                    mt: {xs: "10px", md: "15px", lg: "21.6px"},
                    flexDirection: {xs: "column", md: "row"},
                    textAlign: {xs: "center", md: "left"},
                    py: {xs: "10px", md: "0px"},
                    justifyContent: "space-between"
                }}
            >
                {renderBalanceBox("Starting Balance", (Number(passengerProfile.milesEarnt) || 0) + (Number(passengerProfile.milesAccountBalance) || 0))}
                <Box
                    sx={{
                        borderRightStyle: {xs: "none", md: "solid"},
                        borderRightWidth: {xs: "0px", md: "1px"},
                        borderColor: "#212121",
                        width: {xs: "100%", md: "auto"},
                        mb: {xs: "10px", md: "0px"},
                    }}
                ></Box>
                {renderBalanceBox("Purchase Amount", passengerProfile.selectedAncillaries.reduce((acc, item) => acc + (Number(item.price) || 0), 0))}
                <Box
                    sx={{
                        borderRightStyle: {xs: "none", md: "solid"},
                        borderRightWidth: {xs: "0px", md: "1px"},
                        borderColor: "#212121",
                        width: {xs: "100%", md: "auto"},
                        mb: {xs: "10px", md: "0px"},
                    }}
                ></Box>
                {renderBalanceBox("Remaining Balance",
                    (Number(passengerProfile.milesEarnt) || 0) +
                    (Number(passengerProfile.milesAccountBalance) || 0) -
                    passengerProfile.selectedAncillaries.reduce((acc, item) => acc + (Number(item.price) || 0), 0)
                )}
            </Box>

            <Field name="email">
                {({field}: any) => (
                    <Tooltip
                        title={errors.email}
                        open={!!errors.email}
                        arrow
                        sx={{zIndex: 1}}
                        placement="bottom-end"
                    >
                        <InputBase
                            {...field}
                            value={values.email}
                            onChange={(e) => {
                                setFieldValue("email", e.target.value);
                                setUserEmail(e.target.value);
                            }}
                            sx={{
                                width: {xs: "100%", lg: "490px"},
                                mt: "8px",
                                background: "white",
                                borderRadius: "5px",
                                maxHeight: "44px",
                                border: "1px solid #212121",
                                px: "10px",
                                fieldset: {
                                    display: "none",
                                },
                            }}
                            placeholder="Enter recipient's email"
                        />
                    </Tooltip>
                )}
            </Field>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs: "column", lg: "row"},
                    justifyContent: "center",
                    mt: "17px",
                    gap: "15px",
                    pb: {xs: "30px", lg: "0px"},
                }}
            >
                <Button
                    type="submit"
                    onClick={() => {
                        handleSubmit();
                      }}
                    sx={{
                        background: "linear-gradient(90deg, #262465 0%, #265BB0 100%)",
                        color: "#FFFFFF",
                        textTransform: "none",
                        fontSize: "12px",
                        "&:hover": {
                            background: "linear-gradient(90deg, #262465 0%, #265BB0 100%)",
                        },
                    }}
                    variant="contained"
                >
                    Confirm Purchase
                </Button>
                <Button
                    onClick={() => setIsConfirm(false)}
                    sx={{
                        border: "1px solid #4B4B4B",
                        color: "#4B4B4B",
                        textTransform: "none",
                        fontSize: "12px",
                        backgroundColor: "transparent",
                        "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                    }}
                >
                    Back to selection
                </Button>
            </Box>
        </Box>
    );

    const renderSelection = () => (
        <Box sx={{px: {xs: "8px", lg: "24px"}}}>
            <Typography sx={{fontSize: "16px", fontWeight: 700, mt: "20px"}}>
                Thank you for flying with us!
            </Typography>
            <Typography sx={{fontSize: "14px", mt: "17px"}}>
                We hope you've enjoyed your journey. As a member of our Etihad
                Guest loyalty programme, we have now credited{" "}
                <strong>{formatNumber(Number(passengerProfile.milesEarnt) || 0)} Miles </strong> to
                your account.
            </Typography>
            <Typography sx={{fontSize: "14px", mt: "24px"}}>
                For a limited time only, you can now{" "}
                <strong>swap your miles </strong> into what we think would
                make your next flight or onward journey even more enjoyable.
            </Typography>
            <Typography sx={{fontSize: "16px", fontWeight: 700, mt: "15px"}}>
                Extras
            </Typography>
            <Typography sx={{fontSize: "14px", fontWeight: 400, mt: "8px", mb: "15px"}}>
                You can now choose which free benefits to enjoy on your next
                flight:
            </Typography>
            <Box>
                <Grid container spacing={2} justifyContent="center">
                    {ancillaries.map((item, index) => {
                        const isSelected = selectedAncillaries.includes(item.name);
                        const isDisabled = !canSelectAncillary(item) && !isSelected;

                        return (
                            <Grid item xs={12} sm={6} md={6} lg={4} key={item.name + index}>
                                <div style={{cursor: isDisabled ? 'not-allowed' : 'pointer'}}>
                                    <Tooltip
                                        title={isDisabled ? "Not enough miles." : ""}
                                        arrow
                                    >
                                        <Box sx={{opacity: isDisabled ? 0.5 : 1, transition: 'opacity 0.3s'}}>
                                            <Airline
                                                value={item}
                                                imageSrc={item.imageUrl}
                                                title={item.name}
                                                miles={formatNumber(item.price)}
                                                selectedAncillaries={selectedAncillaries}
                                                toggleAncillary={(name, value) => !isDisabled && toggleAncillary(name, value)}
                                                size="small"
                                            />
                                        </Box>
                                    </Tooltip>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <Box sx={{display: "flex", width: "100%", justifyContent: "center"}}>
                <Tooltip
                    title={!passengerProfile.selectedAncillaries?.length ? "Select Ancillaries to complete Purchase" : ""}
                    arrow
                    sx={{
                        opacity: !passengerProfile.selectedAncillaries?.length ? 1 : 0,
                    }}
                >
                    <span>
                        <Button
                            variant="contained"
                            sx={{
                                height: "33px",
                                width: "141px",
                                bgcolor: "#4B4B4B",
                                color: "white",
                                textTransform: "none",
                                maxWidth: "160px",
                                mt: "32px",
                                borderRadius: "5px",
                                p: "0px",
                                mb: {xs: "26px", md: "16px"},
                            }}
                            onClick={() =>
                                passengerProfile.selectedAncillaries?.length &&
                                setIsConfirm(true)
                            }
                            disabled={!passengerProfile.selectedAncillaries?.length}
                        >
                            Complete Purchase
                        </Button>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );

    return (
        <Box
            sx={{
                height: {xs: "auto", lg: "570px"},
                width: "100%",
                borderRadius: '32px'
            }}
        >
            <Formik
                initialValues={{
                    ...passengerProfile,
                    selectedAncillaries: passengerProfile.selectedAncillaries || []
                }}
                validationSchema={validationPurchaseSchema}
                onSubmit={handleCompletePurchase}
            >
                {(formikProps) => (
                    <Box
                        sx={{
                            height: {xs: "auto", lg: "570px"},
                            width: "100%",
                            bgcolor: "transparent",
                            borderRadius: '32px',
                            overflow: {lg: "scroll"},
                        }}
                    >
                        {renderHeader()}
                        {isConfirm ? (
                            <>
                                <OrderConfirmationEmail
                                    ref={orderConfirmationEmailRef}
                                    passengerProfile={passengerProfile}
                                    selectedAncillaries={selectedAncillaries}
                                    formatNumber={formatNumber}
                                    setEmailHTML={setEmailHTML}
                                    emailHTML={emailHTML}
                                    onLogHTML={() => console.log("Email HTML logged")}
                                />
                                {renderConfirmation(formikProps)}
                            </>
                        ) : (
                            renderSelection()
                        )}
                    </Box>
                )}
            </Formik>
        </Box>
    );
};

export default AirlineList;