import { Box, Grid } from "@mui/material";
import React, {useState} from "react";
import Header from "./Header";
import PassengerPersona from "./PassengerPersona";
import ProfileDetail from "./ProfileDetail";
import RecentFlightDetails from "./RecentFlightDetails";
import TravelDetail from "./TravelDetail";
import { useStep } from "../context/StepContext";
import { useLoading } from "../context/LoadingContext";
import {usePassenger} from "../context/PassengerContext";

interface PassengerProfileProps {
  passengerData: object;
  setPassengerData: React.Dispatch<React.SetStateAction<object>>;
  setIsPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviewLoaded: boolean;
}

const PassengerProfile: React.FC<PassengerProfileProps> = ({ isPreviewLoaded, setIsPreviewLoaded }) => {
  const { step } = useStep();
  const { setStep } = useStep();
  const { setIsLoading } = useLoading();
  const { setPassengerProfile } = usePassenger();

  const handleBackButton = () => {
    setStep("custom");
    setIsPreviewLoaded(false);
    setPassengerProfile(prevProfile => ({
      ...prevProfile,
      selectedAncillaries: []
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "100%",
        minHeight: "100vh",
        background:
          "radial-gradient(74.58% 201.5% at 100% 8.64%, #391847 59.5%, #0D0D0D 100%)",
        padding: { xs: "20px", md: "0px" },
        boxSizing: "border-box",
      }}
    >
      <Grid container  sx={{ marginTop: '150px', marginBottom: '51px',marginLeft:'-6px' }}>
        <Grid item lg={7.5} sm={12} xs={12} md={12} sx={{
          bgcolor: "#1F1828", padding: "24px", borderTopRightRadius: '24px',borderBottomRightRadius:'24px',boxShadow:'2px 0px 8px 0px #00000040;',zIndex:'999'
        }}>
          {step === "personaType" ? (
            <PassengerPersona />
          ) : step === "defaultDetail" || step === "loadPreview" || step === "complete" ? (
            <TravelDetail onBackButton={handleBackButton} isPreviewLoaded={isPreviewLoaded} setIsPreviewLoaded={setIsPreviewLoaded}/>
          ) : (
            <ProfileDetail />
          )}
        </Grid>
        <Grid item lg={4.5} xs={12} sm={12} md={12}>
          <RecentFlightDetails setIsLoading={setIsLoading} setIsPreviewLoaded={setIsPreviewLoaded}/>
        </Grid>
      </Grid>
    </Box>

  );
};

export default PassengerProfile;
