import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useStep } from "../context/StepContext";
import { usePassenger } from "../context/PassengerContext";
import { personaTypes } from "../constants/Constants";

const PassengerPersona: React.FC<{}> = () => {
  const { setStep } = useStep();
  const { passengerProfile, setPassengerProfile } = usePassenger();

  return (
    <Box sx={{ display: "flex", flexDirection: "column",padding:'16px' }}>
      <Typography
        sx={{
          fontSize: { xs: "20px", lg: "24px" },
          fontWeight: 600,
          color: "white",
          pb: "10px",
        }}
      >
        Select a Passenger Persona
      </Typography>

      {personaTypes.map((item) => (
        <Box
          key={item.label}
          onClick={() => {
            setPassengerProfile({
              ...passengerProfile,
              stopover: item.stopover,
              age: item.age,
              gender: item.gender,
              travelGroup:item.travelGroup,
              purposeOfTravel: item.purposeOfTravel,
              loyaltyStatus: item.loyaltyStatus,
              services: item.services as string[],
              milesAccountBalance: item.milesAccountBalance,
              personaType: {
                heading: item.label,
                travelOptions: item.travelOptions,
                personalDetailOptions: item.personalDetailOptions,
              },
            });
            setStep("defaultDetail");
          }}
          sx={{
            mt: "16px",
            width: "100%",
            height: "50px",
            bgcolor: "#36303D",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
                width: "50px",
                bgcolor: "#BB1BEF",
                borderRadius: "10px",
              }}
            >
              <img
                src={item.imagePath}
                alt={item.label}
                style={{ width: 26, height: 26, objectFit: "contain" }}
              />
            </Box>
            <Typography sx={{ fontSize: "12px", color: "white" }}>
              {item.label}
            </Typography>
          </Box>
          <ChevronRightIcon sx={{ fill: "#C3A8E5", mr: "18px" }} />
        </Box>
      ))}

      <Box
        onClick={() => setStep("custom")}
        sx={{
          cursor: "pointer",
          background: "linear-gradient(97.92deg, #D922FF 0%, #5000B5 99.93%)",
          width: "100%",
          minHeight: "34px",
          borderRadius: "10px",
          mt: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "14px",
        }}
      >
        <KeyboardArrowLeftIcon sx={{ color: "white", fontSize: "18px" }} />
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "21px",
              height: "21px",
              borderRadius: "21px",
              bgcolor: "#6E0E9D",
            }}
          >
            <img
              src={require("../assets/images/avatar.png")}
              alt={"avatar"}
              style={{ width: 12, height: 12, objectFit: "contain" }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              color: "white",
            }}
          >
            Back to Manual Customisation
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default PassengerPersona;
