import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { Ancillary } from "../types/Ancillary";

interface AirlineProps {
  imageSrc: string;
  title: string;
  miles: number | string;
  toggleAncillary: (ancillaryName: string, value: Ancillary) => void;
  selectedAncillaries: string[];
  value: Ancillary;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const Airline: React.FC<AirlineProps> = ({
  title,
  value,
  miles,
  toggleAncillary,
  selectedAncillaries,
  imageSrc,
  disabled,
  size = 'medium',
}) => {
  const isSelected = selectedAncillaries.includes(title);
  const [isHovered, setIsHovered] = useState(false);

  const sizeStyles = {
    small: {
      minWidth: '180px',
      fontSize: {
        title: '10px',
        add: '10px',
        miles: '10px',
      },
      iconSize: '16px',
    },
    medium: {
      minWidth: '250px',
      fontSize: {
        title: '12px',
        add: '12px',
        miles: '10px',
      },
      iconSize: '20px',
    },
    large: {
      minWidth: '300px',
      fontSize: {
        title: '14px',
        add: '14px',
        miles: '12px',
      },
      iconSize: '24px',
    },
  };

  const selectedSize = sizeStyles[size];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: selectedSize.minWidth,
        width: '100%',
        maxWidth: '400px', // Adjust this value as needed
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: isSelected ? "#015F96" : (isHovered && !disabled ? "#015F96" : "#DCDBDB"),
        overflow: "hidden",
        position: "relative",
        cursor: "pointer",
        opacity: 1,
        transition: "all 0.3s ease",
      }}
      onClick={() => !disabled && toggleAncillary(title, value)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          width: "100%",
          paddingTop: "75%", // 4:3 aspect ratio
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={imageSrc}
          alt={title}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: isSelected ? "brightness(0.65)" : "none",
            transition: "filter 0.3s ease",
          }}
        />
        {isSelected ? (
          <CheckCircleIcon
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              color: '#015F96',
              fontSize: selectedSize.iconSize,
              backgroundColor: 'white',
              borderRadius: '50%',
            }}
          />
        ) : (
          <AddIcon
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              color: 'white',
              fontSize: selectedSize.iconSize,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              padding: '2px',
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          bgcolor: "white",
          padding: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: selectedSize.fontSize.title,
            fontWeight: 700,
            lineHeight: 1.2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: '4px',
            flexWrap: 'nowrap',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: selectedSize.fontSize.add,
              fontWeight: 700,
              color: isSelected ? "#8A8A8A" : "#015F96",
              marginRight: '4px',
            }}
          >
            {isSelected ? "Added" : "Add"}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: selectedSize.fontSize.miles,
              fontWeight: 700,
              whiteSpace: 'nowrap',
            }}
          >
            {miles} Miles
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Airline;