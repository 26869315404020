import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#391847',
      paper: '#403451',
    },
  },
  typography: {
    fontFamily: '"Noto Sans", sans-serif',
    fontSize: 12,
    h1: {
      fontSize: '40px',
    },
    h2: {
      fontSize: '24px'
    },
    h3: {
      fontSize: '20px'
    },
    h4: {
      fontSize: '14px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body {
          height: 100%;
        }
        body {
          background: linear-gradient(to bottom, #391847, #1F1828);
          background-attachment: fixed;
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 100vh;
        }
        #root {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }
      `,
    },
  },
});

export default theme;