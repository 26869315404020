import React, { createContext, useState, ReactNode, useContext } from "react";
import { PassengerContextType, PassengerProfile } from "../types/Passenger";
import { initialProfile } from "../constants/Constants";

export const PassengerContext = createContext<PassengerContextType | undefined>(
  undefined
);

export const PassengerProvider = ({ children }: { children: ReactNode }) => {
  const [passengerProfile, setPassengerProfile] =
    useState<PassengerProfile>(initialProfile);

  return (
    <PassengerContext.Provider
      value={{ passengerProfile, setPassengerProfile }}
    >
      {children}
    </PassengerContext.Provider>
  );
};

export const usePassenger = (): PassengerContextType => {
  const context = useContext(PassengerContext);
  if (!context) {
    throw new Error("usePassenger must be used within a PassengerProvider");
  }
  return context;
};
