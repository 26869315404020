import React from "react";
import { Slider, Box, Typography, IconButton } from "@mui/material";
import { useStep } from "../context/StepContext";
import InfoIcon from "@mui/icons-material/Info";

interface MilesBalanceSliderProps {
  initialBalance: number;
  maxBalance: number;
  onChange: (value: number) => void;
  setInfo: (
    value: React.SetStateAction<{
      heading: string;
      detail: string;
    }>
  ) => void;
  handleInfoClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const MilesBalanceSlider: React.FC<MilesBalanceSliderProps> = ({
  initialBalance,
  maxBalance,
  onChange,
  setInfo,
  handleInfoClick,
}) => {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };
  const { step } = useStep();

 const formatNumber = (value: number): string => {
  if (value >= 1000) {
    return value.toLocaleString();
  }
  return value.toString();
};

  return (
    <Box
      sx={{
        color: "white",
        mt: "15px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", alignSelf: "center" }}>
        <Typography sx={{ fontSize: "14px", alignSelf: "center" }} gutterBottom>
          Miles Account Balance - <strong>{formatNumber(initialBalance)} Miles</strong>
        </Typography>
        <IconButton
          sx={{ display: { xs: "none", md: "unset" } }}
          onClick={(e) => {
            setInfo({
              heading: "Account Miles Balance",
              detail:
                "How many loyalty points do you have in your account? Rather than let them expire or become forgotten, our algorithm can allow you to make the most of your points balance.",
            });
            handleInfoClick(e);
          }}
        >
          <InfoIcon style={{ color: "#C3A8E5" }} />
        </IconButton>
      </Box>
      <Slider
        value={initialBalance}
        max={maxBalance}
        disabled={step !== "custom"}
        onChange={handleSliderChange}
        aria-labelledby="miles-account-balance-slider"
        sx={{
          color: "#9c27b0",
          height: 8,
          "& .MuiSlider-track": {
            border: "none",
          },
          "& .MuiSlider-rail": {
            opacity: 0.5,
            backgroundColor: "#bfbfbf",
          },
        }}
      />
      <Box display="flex" justifyContent="space-between" mt="-8px">
        <Typography sx={{ color: "#A4A4A4", fontSize: "10px" }}>0</Typography>
        <Typography sx={{ color: "#A4A4A4", fontSize: "10px" }}>
          {maxBalance}
        </Typography>
      </Box>
    </Box>
  );
};

export default MilesBalanceSlider;
