import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {Box, Typography} from "@mui/material";
import {boolean, string} from "yup";
import React from "react";
import {PassengerProfile} from "../types/Passenger";

interface SelectAirlineHeaderProps {
    setStep: (value: (((prevState: string) => string) | string)) => void,
    setIsComplete: (value: (((prevState: { isLoading: boolean; isDone: boolean }) => {
        isLoading: boolean;
        isDone: boolean
    }) | { isLoading: boolean; isDone: boolean })) => void,
    setPreviewData: (value: any) => void,
    setIsPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>,
    isConfirm: boolean,
    setIsConfirm: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    setPassengerProfile: (value: (((prevState: PassengerProfile) => PassengerProfile) | PassengerProfile)) => void
}

const SelectAirlineHeader: React.FC<SelectAirlineHeaderProps> = ({
                                                                     setStep,
                                                                     setIsComplete,
                                                                     setPreviewData,
                                                                     setIsPreviewLoaded,
                                                                     isConfirm,
                                                                     setIsConfirm,
                                                                     setPassengerProfile
                                                                 }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                px: "20px",
                gap: "20px",
            }}
        >
            <KeyboardBackspaceIcon
                sx={{fontSize: "18px", cursor: "pointer"}}
                onClick={() => {
                    if (isConfirm) {
                        setIsConfirm(false);
                    } else {
                        setStep("defaultDetail")
                        setIsPreviewLoaded(false)
                        setIsComplete({isDone: false, isLoading: false})
                        setPreviewData(null);
                        setPassengerProfile(prevProfile => ({
                            ...prevProfile,
                            selectedAncillaries: []
                        }));
                    }
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: "10px",
                    py: "4px",
                    border: "1.5px solid #000000",
                    height: "24px",
                    width: "86px",
                    gap: "4px",
                    borderRadius: "5px",
                }}
            >
                <img
                    src={require("../assets/images/inbox.png")}
                    alt={"inbox"}
                    style={{width: 16, height: 16, objectFit: "contain"}}
                />
                <Typography sx={{fontSize: "10px", fontWeight: 700}}>
                    INBOX
                </Typography>
                <img
                    src={require("../assets/images/padlock.png")}
                    alt={"inbox"}
                    style={{width: 12, height: 12, objectFit: "contain"}}
                />
            </Box>
        </Box>
    );
};

export default SelectAirlineHeader;
