// ProfileDetail.tsx
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoIcon from "@mui/icons-material/Info";
import Person from "@mui/icons-material/Person";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import {
  FlightOptions,
  GenderOptions,
  infoDetails,
  loyaltyStatusOptions,
  services,
  travelPurposeOptions,
} from "../constants/Constants";
import { usePassenger } from "../context/PassengerContext";
import { useStep } from "../context/StepContext";
import { profileSchema } from "../utils/validation";
import AppCheckbox from "./AppCheckbox";
import AppSelector from "./AppSelector";
import MilesBalanceSlider from "./MilesBalanceSlider";

const ProfileDetail: React.FC = () => {
  const [isEditable, setIsEditable] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { step, setStep } = useStep();
  const { passengerProfile, setPassengerProfile } = usePassenger();
  const [info, setInfo] = useState({ heading: "", detail: "" });

  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSubmit = (values: any) => {
    setPassengerProfile({
      ...values,
      services: values.services,
      travelGroup: values.travelGroup,
      milesAccountBalance: values.milesBalance,
      personaType: {
        heading: "Custom Profile",
        travelOptions: [
          {
            firstLabel: "Travel Purpose",
            secondLabel: values.purposeOfTravel,
            value: values.purposeOfTravel,
          },
          {
            firstLabel: "Miles Balance",
            secondLabel: values.milesBalance.toLocaleString() + "",
            value: values.milesBalance.toLocaleString() + "",
          },
          {
            firstLabel: "Travel Group",
            secondLabel: values.travelGroup,
            value: values.travelGroup
          },
          {
            firstLabel: "Stopover",
            secondLabel: values.stopover ? "Yes" : "No",
            value: values.stopover ? "Yes" : "No",
          },
          {
            firstLabel: "Most Purchased Ancillaries",
            secondLabel: values.services.length > 0 ? values.services.join(",") : "N/A",
            value: values.services.length > 0 ? values.services.join(",") : "N/A",
          },
        ],
        personalDetailOptions: [
          { firstLabel: "Age", secondLabel: values.age, value: values.age },
          {
            firstLabel: "Gender",
            secondLabel: values.gender,
            value: values.gender,
          },
        ],
      },
    });

    setStep("defaultDetail");
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" bgcolor='#1F1828'>
      <Formik
        initialValues={{
          age: passengerProfile.age || "",
          gender: passengerProfile.gender || "",
          purposeOfTravel: passengerProfile.purposeOfTravel || "",
          //loyaltyStatus: passengerProfile.loyaltyStatus || "",
          milesBalance: passengerProfile.milesAccountBalance || 0,
          services: passengerProfile.mostPurchasedAncillaries || [],
          stopover: passengerProfile.stopover || false,
          travelGroup: passengerProfile.travelGroup || 0,
        }}
        validationSchema={profileSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
          setFieldTouched,
          touched,
          handleSubmit,
        }) => (
          <>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                backgroundColor:'rgba(0, 0, 0, 0.5)',
                transition: 'background-color 0.3s ease', 
              }}
              PaperProps={{
                sx: {
                  border: "1px solid #C3A8E5",
                  bgcolor: "#2F2836",
                  width: "213px",
                  borderRadius: "10px",
                  color: "white",
                },
              }}
            >
              <Box
                sx={{
                  bgcolor: "#C3A8E5",
                  width: "213px",
                  height: "32px",
                  p: "8px",
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                  {info.heading}
                </Typography>
              </Box>
              <Box sx={{ p: "10px" }}>
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  {info.detail}
                </Typography>
              </Box>
            </Popover>

            <Box sx={{ display: "flex" }} p={1}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                }}
              >
                <IconButton
                  onClick={(e) => {
                    setInfo(infoDetails.defaultPersona);
                    handleInfoClick(e);
                  }}
                  aria-describedby={id}
                  sx={{ mt: "40px" }}
                >
                  <InfoIcon style={{ color: "#C3A8E5" }} />
                </IconButton>

                <IconButton
                  onClick={(e) => {
                    setInfo(infoDetails.age);
                    handleInfoClick(e);
                  }}
                  aria-describedby={id}
                  sx={{ mt: "25px" }}
                >
                  <InfoIcon style={{ color: "#C3A8E5" }} />
                </IconButton>

                <IconButton
                  onClick={(e) => {
                    setInfo(infoDetails.services);
                    handleInfoClick(e);
                  }}
                  aria-describedby={id}
                  sx={{ mt: "255px" }}
                >
                  <InfoIcon style={{ color: "#C3A8E5" }} />
                </IconButton>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "white",
                  }}
                  variant="h2"
                >
                  Design a Passenger Profile
                </Typography>

                <Box
                  onClick={() => step === "custom" && setStep("personaType")}
                  sx={{
                    cursor: "pointer",
                    background:
                      "linear-gradient(97.92deg, #D922FF 0%, #5000B5 99.93%)",
                    width: "100%",
                    minHeight: "34px",
                    borderRadius: "10px",
                    mt: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pl: "14px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "21px",
                        height: "21px",
                        borderRadius: "21px",
                        bgcolor: "#6E0E9D",
                      }}
                    >
                      <Person sx={{ fill: "white", fontSize: "14px" }} />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '11px', sm: '13px' },
                        color: "white",
                      }}
                    >
                      Or Select a Default Passenger Persona
                    </Typography>
                  </Box>

                  <ArrowForwardIosIcon
                    sx={{ color: "white", fontSize: "18px", mr: "8px" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  
                  <Field name="age">
                    {({ field, meta }: any) => (
                      <Tooltip
                        title={touched.age && errors.age ? errors.age : ""}
                        open={!!(touched.age && errors.age)}
                        arrow
                        sx={{ zIndex: 1 }}
                        placement="bottom-end"
                      >
                        <InputBase
                          {...field}
                          type="number"
                          disabled={!isEditable}
                          sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                              textFillColor: '#fff', // Text color for disabled state
                            },
                            bgcolor: "#36303D",
                            minWidth: "127px",
                            maxWidth: "127px",
                            height: "50px",
                            borderRadius: "10px",
                            px: "17px",
                            py: "17px",
                            mt: "16px",
                            color: "white",
                            border:
                              errors.age && touched.age
                                ? "1px solid #C3A8E5"
                                : "none",
                            "&.Mui-focused": {
                              border: "1px solid #C3A8E5",
                            },
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              "-moz-appearance": "textfield",
                            },
                          }}
                          placeholder="Age"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => {
                            setIsFocused(false);
                            setFieldTouched("age");
                          }}
                          endAdornment={
                            !isFocused && (
                              <InputAdornment position="end">
                                <Typography
                                  sx={{
                                    color: "#CCCCCC",
                                    fontSize: "12px",
                                    opacity: isEditable ? "0.3" : 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setIsEditable(true)} //
                                >
                                {/*field.value ? "Edit" : "Enter"*/}
                                </Typography>
                              </InputAdornment>
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </Field>

                  <Field name="gender">
                    {({ field, meta }: any) => (
                      <AppSelector
                        disable={step !== "custom"}
                        options={GenderOptions}
                        placeHolder="Gender"
                        value={field.value}
                        error={errors.gender || ""}
                        onChange={(value) => setFieldValue("gender", value)}
                        touched={touched.gender}
                        onBlur={() => setFieldTouched("gender")}
                      />
                    )}
                  </Field>
                </Box>

                <Field name="purposeOfTravel">
                  {({ field, meta }: any) => (
                    <AppSelector
                      error={errors.purposeOfTravel || ""}
                      disable={step !== "custom"}
                      options={travelPurposeOptions}
                      placeHolder="Purpose of Travel"
                      value={field.value}
                      onChange={(value) =>
                        setFieldValue("purposeOfTravel", value)
                      }
                      onBlur={() => setFieldTouched("purposeOfTravel")}
                      touched={touched.purposeOfTravel}
                    />
                  )}
                </Field>

                {/* <Field name="loyaltyStatus">
                  {({ field, meta }: any) => (
                    <AppSelector
                      error={errors.loyaltyStatus || ""}
                      disable={step !== "custom"}
                      options={loyaltyStatusOptions}
                      placeHolder="Loyalty Status"
                      value={field.value}
                      onChange={(value) =>
                        setFieldValue("loyaltyStatus", value)
                      }
                      onBlur={() => setFieldTouched("loyaltyStatus")}
                      touched={touched.loyaltyStatus}
                    />
                  )}
                </Field> */}

                <MilesBalanceSlider
                  initialBalance={values.milesBalance}
                  maxBalance={100000}
                  onChange={(value) => setFieldValue("milesBalance", value)}
                  handleInfoClick={handleInfoClick}
                  setInfo={setInfo}
                />

                <Box display="flex">
                  <Tooltip
                    title={errors.services}
                    open={!!errors.services}
                    arrow
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        background: "linear-gradient(0deg, #36303D, #36303D)",
                        borderRadius: "10px",
                        pt: "0px",
                        px: "0px",
                        padding: "11px",
                        width: "59%",
                        opacity: step === "custom" ? 1 : 0.5,
                        mt: "19px",
                        pb: "0px",
                        height: "293px",
                        border: !!errors.services
                          ? "1px solid #C3A8E5"
                          : "1px solid #36303D",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          pb: "5px"
                        }}
                        variant='h4'
                      >
                        Most Purchased Ancillaries
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          px: "0px",
                          minWidth: "180px",
                          gap: { xs: "8px", lg: "3.75px" },
                        }}
                      >
                        {services.map((service) => (
                          <AppCheckbox
                            key={service}
                            label={service}
                            checked={values.services.includes(service)}
                            onChange={(checked) => {
                              const updatedServices = checked
                                ? [...values.services, service]
                                : values.services.filter(
                                  (s: string) => s !== service
                                );
                              setFieldValue("services", updatedServices);
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Tooltip>

                  <Box
                    sx={{
                      mt: "19px",
                      pt: "12px",
                      pl: "15px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", }}
                    >
                      <Box>
                        <Typography fontSize={{ xs: "12px", sm: "14px" }} sx={{ pl: { xs: "0px", sm: "12px" }, color: "white" }}>
                          Stopover:
                        </Typography>
                        <IconButton
                          sx={{ display: { xs: "none", md: "unset" } }}
                          onClick={(e) => {
                            setInfo(infoDetails.stopover);
                            handleInfoClick(e);
                          }}
                          aria-describedby={id}
                        >
                          <InfoIcon style={{ color: "#C3A8E5" }} />
                        </IconButton>
                      </Box>

                      <AppCheckbox
                        label="Yes"
                        styles={{ flexDirection: "column", gap: "2px" }}
                        checked={values.stopover === true}
                        onChange={(checked) =>
                          setFieldValue("stopover", checked)
                        }
                      />
                      <AppCheckbox
                        label="No"
                        styles={{ flexDirection: "column", gap: "2px" }}
                        checked={values.stopover === false}
                        onChange={(checked) =>
                          setFieldValue("stopover", !checked)
                        }
                      />
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ pl: { xs: "0px", sm: "12px" }, fontSize: "14px", color: "white" }}>
                        Traveller Group
                      </Typography>

                      <IconButton
                        sx={{ display: { xs: "none", md: "unset" } }}
                        onClick={(e) => {
                          setInfo(infoDetails.travelGroup);
                          handleInfoClick(e);
                        }}
                        aria-describedby={id}
                      >
                        <InfoIcon style={{ color: "#C3A8E5" }} />
                      </IconButton>
                    </Box>

                    <Field name="travelGroup">
                      {({ field, meta }: any) => (
                        <AppSelector
                          error={errors.travelGroup || ""}
                          disable={step !== "custom"}
                          options={FlightOptions}
                          placeHolder="Select"
                          value={field.value || ""}
                          onChange={(value) =>
                            setFieldValue("travelGroup", value)
                          }
                          onBlur={() => setFieldTouched("travelGroup")}
                          touched={touched.travelGroup}
                        />
                      )}
                    </Field>

                    <Button
                      type="submit"
                      variant={step === "custom" ? "text" : "contained"}
                      sx={{
                        color: "white",
                        textTransform: "none",
                        maxWidth: "auto",
                        alignSelf: "flex-end",
                        fontSize: { xs: "12px", sm: "14px" },
                        mt: "65px",
                        background: "linear-gradient(97.92deg, #D922FF 0%, #5000B5 99.93%)",
                        borderRadius: "12px",
                      }}
                      disabled={step !== "custom"}
                      onClick={() => handleSubmit()}
                    >
                      Confirm Profile
                    <ArrowForwardIosIcon
                      sx={{ color: "white", fontSize: "18px", ml: "8px" }}
                    />
                    </Button>

                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default ProfileDetail;
