import React from "react";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Button, Menu, MenuItem, Typography, SxProps, Theme } from "@mui/material";

interface DropdownItem {
  title?: string;
  from: string;
  to: string;
  distance?: number;
}

interface AppDropdownProps {
  title?: string;
  items: DropdownItem[];
  openUpwards?: boolean;
  step: string;
  value: string | { from: string; to: string };
  onChange: (value: string) => void;
  sx?: SxProps<Theme>;
  multipliers?: { [key: string]: number };
}

const AppDropdown: React.FC<AppDropdownProps> = ({
  title,
  items,
  openUpwards = false,
  step,
  value,
  onChange,
  sx,
  multipliers,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedItem = items.find((item) => `${item.from} ${item.to}` === value) || null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="dropdown-button"
        disabled={!(step === "confirm" || step === "defaultDetail")}
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          width: "100%",
          height: "80px",
          borderRadius: "10px",
          bgcolor: "#534C59",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: open ? "#E6C5FF" : "#534C59",
          boxShadow: "0px 4px 4px 0px #00000040",
          color: "white",
          textTransform: "none",
          padding: "0 16px",
          "&:hover": {
            bgcolor: "#534C59",
          },
          ...sx,
        }}
      >
        <Typography
          className="title"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            opacity: 0.8,
            fontSize:14
          }}

        >
          {title}
          <Typography
            className="value"
            sx={{
              fontSize: openUpwards ? "18px" : "20px",
              fontWeight: 600,
              opacity: 1,
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
            variant='h3'
          >
            {selectedItem ? (
              <>
                {selectedItem.from}{" "}
                {!openUpwards && (
                  <TrendingFlatIcon sx={{ fill: "#E6C5FF" }} />
                )}{" "}
                {selectedItem.to}
              </>
            ) : (
              title
            )}
          </Typography>
        </Typography>
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: openUpwards ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: openUpwards ? "bottom" : "top",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "dropdown-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            bgcolor: "#534C59",
            color: "white",
            width: "255px",
            borderRadius: "10px",
            mt: openUpwards ? "-1px" : "1px",
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              onChange(`${item.from} ${item.to}`);
            }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "53px",
              borderRadius: "10px",
              "&:hover": {
                bgcolor: "#FFFFFF1A",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: openUpwards ? "16px" : "20px",
                fontWeight: 600,
                opacity: 1,
                display: "flex",
                alignItems: "center",
                gap: "2px"
              }}
              variant='h3'
            >
              {item.from}{" "}
              {!openUpwards && <TrendingFlatIcon sx={{ fill: "#E6C5FF" }} />}{" "}
              {item.to}
            </Typography>
            <Typography component="div" display="flex" flexDirection="column" alignItems="flex-end">
              {item.title && (
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {item.title}
                </Typography>
              )}
              {item?.distance && (
                <Typography component="div" sx={{ display: "flex", gap: "6px" }}>
                  <Typography
                    sx={{
                      fontWeight: 300,
                    }}
                  >
                    Miles:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {item?.distance}
                  </Typography>
                </Typography>
              )}
              {multipliers && multipliers[item.to || item.from] && multipliers[item.to || item.from] !== 1 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {multipliers[item.to || item.from]}x Miles
                  </Typography>
                )}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AppDropdown;