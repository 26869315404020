import React, { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InfoIcon from "@mui/icons-material/Info";

import {
  infoDetails,
  fareClasses,
  recentFlights,
  flightDistanceMultiplier,
  loyaltyStatusMultiplier,
  fareClassMultiplier,
  loyaltyStatuses
} from "../constants/Constants";
import { usePassenger } from "../context/PassengerContext";
import { useStep } from "../context/StepContext";
import { recentFlightSchema } from "../utils/validation";
import AppDropdown from "./AppDropdown";
import AppInputBase from "./AppInputBase";
import { PassengerProfile } from "../types/Passenger";

interface RecentFlightDetailsProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const RecentFlightDetails: React.FC<RecentFlightDetailsProps> = ({
  setIsLoading,
  setIsPreviewLoaded,
}) => {
  const { step, setStep } = useStep();
  const { passengerProfile, setPassengerProfile } = usePassenger();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [milesEarned, setMilesEarned] = useState<number>(0);
  const [recentFlight, setRecentFlight] = useState(() => {
    const flight = passengerProfile.recentFlight.flight;

    if (typeof flight === "object" && flight !== null) {
      return `${flight.from}-${flight.to}`;
    } else if (typeof flight === "string" && flight !== "") {
      return (flight as string).replace(/\s/g, '-');
    } else {
      return "";
    }
  });

  const [loyaltyStatus, setLoyaltyStatus] = useState<string>('');
  const [fareClass, setFareClass] = useState<string>('');

  useEffect(() => {
    const calculateMilesEarned = (profile: typeof passengerProfile) => {
      const route = (recentFlight as string).replace(/\s/g, '-') as keyof typeof flightDistanceMultiplier;
      const loyaltyMultiplier = loyaltyStatusMultiplier[profile.loyaltyStatus as keyof typeof loyaltyStatusMultiplier];
      const fareMultiplier = fareClassMultiplier[fareClass.trim() as keyof typeof fareClassMultiplier];
      console.log(route, loyaltyMultiplier, fareMultiplier);
      return (flightDistanceMultiplier[route] * loyaltyMultiplier * fareMultiplier) || 0;
    };

    setMilesEarned(calculateMilesEarned(passengerProfile));
  }, [recentFlight, fareClass, passengerProfile]);

  const handleSubmit = (values: PassengerProfile, { setSubmitting }: FormikHelpers<PassengerProfile>) => {
    const updatedLoyaltyStatus = getLoyaltyStatus(values.recentFlight.loyaltyStatus);
    const updatedLoyaltyNumber = getLoyaltyNumber(values.recentFlight.class.trim());

    setPassengerProfile({
      ...passengerProfile,
      recentFlight: {
        ...values.recentFlight,
        loyaltyNumber: updatedLoyaltyNumber
      },
      loyaltyStatus: updatedLoyaltyStatus,
      milesEarnt: milesEarned,
    });

    setIsLoading(true);
    setIsPreviewLoaded(true);
    setStep("loadPreview");
  };

  const [info, setInfo] = useState({ heading: "", detail: "" });

  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatNumber = (value: number): string => {
    if (value >= 1000) {
      return value.toLocaleString();
    }
    return value.toString();
  };

  const getLoyaltyStatus = (status: string | { from: string, to: string }): string => {
    if (typeof status === 'string') {
      const statusParts = status.split(' ');
      return statusParts[statusParts.length - 1];
    } else if (status && typeof status === 'object' && 'to' in status) {
      const statusParts = status.to.split(' ');
      return statusParts[statusParts.length - 1];
    }
    return "Non-member";
  };

  const getLoyaltyNumber = (fareClass: string): string => {
    switch (fareClass) {
      case 'Economy':
        return '100105422415';
      case 'Premium Economy':
        return '100105422738';
      case 'Business':
        return '100105422709';
      default:
        return '100105422729';
    }
  };

  const renderMultiplierInfo = (title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined, items: { [s: string]: unknown; } | ArrayLike<unknown>) => (
    <Box sx={{ mb: 2 }}>
      <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 1 }}>
        {title}
      </Typography>
      {Object.entries(items).map(([key, value]) => (
        <Box key={key} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "12px" }}>{key}</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {`${value}x Miles`}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  useEffect(() => {
    if (step === "custom") {
      setRecentFlight("");
      setFareClass("");
      setLoyaltyStatus("");
    }
  }, [step]);


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Formik
      initialValues={passengerProfile}
      validationSchema={recentFlightSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        setFieldValue,
        errors,
        handleSubmit,
        touched,
        setFieldTouched,
      }) => (
        <Box
          component="form"
          onSubmit={handleSubmit}
          marginLeft='-15px'
          zIndex={9999}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: { xs: "center", lg: "unset" },
            height: { xs: "auto" },
            bgcolor: "#322B39E5",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            borderTopLeftRadius: { xs: "20px", md: "0px" },
            borderBottomLeftRadius: { xs: "20px", md: "0px" },
            zIndex: 90,
            marginTop: { xs: "20px", lg: "0px" },
            justifyContent: "flex-start",
            pt: { xs: "20px", lg: "34px" },
            pb: "20px",
            pl: { lg: "38px", xl: "38px" },
            pr: { lg: "38px", xl: "38px" },
            boxShadow: '4px 0px 4px 0px #00000040;',
            ml: { lg: '-15px', md: '24px' },
            p: { md: '24px' },
            mr: { lg: '-31px' },
            position: 'relative'
          }}
        >
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              transition: 'background-color 0.3s ease',
            }}
            PaperProps={{
              sx: {
                border: "1px solid #C3A8E5",
                bgcolor: "#2F2836",
                width: "213px",
                borderRadius: "10px",
                color: "white",
              },
            }}
          >
            <Box sx={{ bgcolor: "#C3A8E5", p: "8px" }}>
              <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                {info.heading}
              </Typography>
            </Box>
            <Box sx={{ p: "10px" }}>
              {info.heading === "Fare Class" && renderMultiplierInfo("Fare Class Multipliers", fareClassMultiplier)}
              {info.heading === "Loyalty Status" && renderMultiplierInfo("Loyalty Status Multipliers", loyaltyStatusMultiplier)}
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                {info.detail}
              </Typography>
            </Box>
          </Popover>
          <Box sx={{ display: "flex", flexDirection: "column" }} width='100%'>
            <Box
              sx={{
                opacity: step === "confirm" || step === "defaultDetail" ? 1 : 0.5,
                display: "flex",
                flexDirection: "column",
                p: { xs: "20px", md: "unset" },
                width: { xs: "100%", md: "unset" },
              }}
            >
              <Typography
                sx={{ fontSize: "20px", color: "white", fontWeight: 600 }}
                variant='h2'
              >
                Recent Flight Details
              </Typography>

              <Box mt="26px" mb="14px" position='relative'>
                <AppDropdown
                  title="Select a flight"
                  items={recentFlights}
                  step={step}
                  value={recentFlight}
                  onChange={(value) => {
                    setFieldValue("recentFlight.flight", value)
                    setRecentFlight(value);
                  }}
                  multipliers={flightDistanceMultiplier}
                />
                <IconButton
                  onClick={(e) => {
                    setInfo(infoDetails.recentFlights);
                    handleInfoClick(e);
                  }}
                  sx={{ position: 'absolute', right: '-39px', top: '22px' }}
                  aria-describedby={id}
                >
                  <InfoIcon style={{ color: "#C3A8E5" }} />
                </IconButton>
              </Box>


              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "26px",
                  mt: "0px",
                  mb: "42px"
                }}
              >
                <Box position='relative'>
                  <AppDropdown
                    title="Select a Class"
                    step={step}
                    items={fareClasses}
                    openUpwards={true}
                    value={fareClass || ""}
                    onChange={(value) => {
                      setFieldValue("recentFlight.class", value);
                      setFareClass(value || "");
                    }}
                    multipliers={fareClassMultiplier}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box position='relative' sx={{ flex: 1 }}>
                      <AppDropdown
                        title="Select a Loyalty Status"
                        step={step}
                        items={loyaltyStatuses}
                        openUpwards={true}
                        value={loyaltyStatus || ""}
                        onChange={(value) => {
                          setLoyaltyStatus(value);
                          setFieldValue("recentFlight.loyaltyStatus", value);
                          setPassengerProfile({
                            ...passengerProfile,
                            loyaltyStatus: getLoyaltyStatus(value),
                            milesEarnt: milesEarned,
                          });
                        }}
                        multipliers={loyaltyStatusMultiplier}
                        sx={{
                          padding: "16px",
                          display: "flex",
                          gap: "0.1rem",
                          color: "#fff",
                          bgcolor: "#7C7C7C",
                          borderRadius: "10px 10px 0 0",
                          "& .title": {
                            fontSize: "14px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            opacity: 0.9,
                          },
                          "& .value": {
                            fontSize: "18px",
                            fontWeight: 600,
                          },
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          setInfo(infoDetails.loyaltyStatus);
                          handleInfoClick(e);
                        }}
                        sx={{ position: 'absolute', right: '-39px', top: '22px' }}
                        aria-describedby={id}
                      >
                        <InfoIcon style={{ color: "#C3A8E5" }} />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        padding: "12px",
                        display: 'flex',
                        gap: 1,
                        color: '#fff',
                        bgcolor: '#534C59',
                        borderRadius: '0 0 10px 10px'
                      }}
                    >
                      <Box fontSize='14px' color='#fff'>
                        Loyalty Number:
                      </Box>
                      <Box color='#fff' fontSize='14px' fontWeight='600'>
                        {getLoyaltyNumber(values.recentFlight.class.trim())}
                      </Box>
                    </Box>
                  </Box>
                 </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "80px",
                  borderRadius: "10px",
                  bgcolor: step === "confirm" || step === "defaultDetail" ? "#BB1BEF" : "#423B48",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  gap: "15px",
                  zIndex: 2,
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    width: "47px",
                    height: "47px",
                    bgcolor: "#81739380",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <ArrowUpwardIcon sx={{ fill: "white" }} />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "12px", color: "white" }}>
                    Miles Earnt From Flight
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "baseline",
                      gap: "6px",
                    }}
                  >
                    {formatNumber(milesEarned)}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "white",
                        fontWeight: 600,
                      }}
                    >
                      Miles
                    </Typography>
                  </Typography>
                </Box>
                <IconButton
                  onClick={(e) => {
                    setInfo(infoDetails.milesEarnt);
                    handleInfoClick(e);
                  }}
                  sx={{
                    position: 'absolute',
                    right: '-39px',
                    top: '50%',
                    transform: 'translateY(-50%)'
                  }}
                  aria-describedby={id}
                >
                  <InfoIcon style={{ color: "#C3A8E5" }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mt: "-13px",
                  width: "100%",
                  height: "97px",
                  borderRadius: "10px",
                  bgcolor: "#1F1828",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  gap: "15px",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    width: "47px",
                    height: "47px",
                    bgcolor: "#21004A80",
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                >
                  <img
                    src={require("../assets/images/speedometer.png")}
                    alt="Logo"
                    style={{ width: 30, height: 30, objectFit: "contain" }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "12px", color: "white" }}>
                    Miles Previously Earned
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "baseline",
                      gap: "6px",
                    }}
                  >
                    {formatNumber(passengerProfile.milesAccountBalance || 0)}{" "}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "white",
                        fontWeight: 600,
                      }}
                    >
                      Miles
                    </Typography>
                  </Typography>
                </Box>
              </Box>

              <Button
                type="submit"
                sx={{
                  color: "#FFFFFF",
                  mr: "-10px",
                  textTransform: "none",
                  mt: "30px",
                  fontSize: "16px",
                  fontWeight: 500,
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  background: fareClass
                    ? "linear-gradient(97.92deg, #A107EA 0%, #5000B5 99.93%)"
                    : "#534C59",
                  "&:hover": {
                    backgroundColor: "#534C58",
                  },
                  "&:disabled": {
                    background: "#534C59",
                    cursor: "not-allowed",
                  },
                }}
                disabled={(step === 'loadPreview') || fareClass === '' || recentFlight === '' || loyaltyStatus === '' || !(step === 'confirm' || step === 'defaultDetail')}
                startIcon={<AutoAwesomeIcon />}
                endIcon={<ChevronRightIcon />}
              >
                Load Preview
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
            }}
          >
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default RecentFlightDetails;
