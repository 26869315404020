import { Box, Typography } from "@mui/material";

const EmailNotification: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "4px",
        mt: "8px",
        pr: "35px",
      }}
    >
        {/*<Typography sx={{ fontSize: "10px", color: "#737373" }}>
        Problems viewing this email?
      </Typography>
      <Typography sx={{ fontSize: "10px", color: "#0078E4" }}>
        click here
      </Typography>*/}
    </Box>
  );
};

export default EmailNotification;
