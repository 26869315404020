import React from 'react';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Button, Divider, Typography } from "@mui/material";
import { usePassenger } from "../context/PassengerContext";
import AppRadioButton from "./AppRadioButton";

interface TravelDetailProps {
  onBackButton: () => void;
  isPreviewLoaded: boolean;
  setIsPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const TravelDetail: React.FC<TravelDetailProps> = ({ onBackButton, isPreviewLoaded, setIsPreviewLoaded}) => {
  const { passengerProfile } = usePassenger();

  const formatNumber = (value: number): string => {
    return value >= 1000 ? value.toLocaleString() : value.toString();
  };

  const getLoyaltyNumber = (fareClass: string): string => {
    switch (fareClass) {
      case 'Economy':
        return '100105422415';
      case 'Premium Economy':
        return '100105422738';
      case 'Business':
        return '100105422709';
      default:
        return '100105422729';
    }
  };

  const recentFlightDetails = passengerProfile.recentFlight && passengerProfile.recentFlight.flight ? [
    {
      firstLabel: "Loyalty Status",
      secondLabel: passengerProfile.loyaltyStatus !== "Non-member" ? `${passengerProfile.loyaltyStatus}` : passengerProfile.loyaltyStatus
    },
    {
      firstLabel: "Flight",
      secondLabel: `${passengerProfile.recentFlight.flight}`
    },
    {
      firstLabel: "Miles Earned",
      secondLabel: `${formatNumber(passengerProfile.milesEarnt)} Miles`
    },
    {
      firstLabel: "Travel Class",
      secondLabel: passengerProfile.recentFlight.class
    },
    {
      firstLabel: "Loyalty Number",
      secondLabel: getLoyaltyNumber(passengerProfile?.recentFlight?.class.trim())
    }
  ] : null;



  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography
          sx={{
            fontSize: { xs: "20px", lg: "24px" },
            fontWeight: 600,
            color: "white",
          }}
        >
          {passengerProfile?.personaType?.heading}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Typography
          sx={{
            pt: "28px",
            fontSize: "16px",
            fontWeight: 400,
            color: "#EADCFA",
          }}
          mb={1}
        >
          Personal Details
        </Typography>
        <Button
          onClick={onBackButton}
          startIcon={<KeyboardArrowLeftIcon />}
          variant="text"
          sx={{
            color: "#EADCFA",
            textTransform: "none",
            alignSelf: "flex-end",
            mt: "-30px",
          }}
        >
          Back
        </Button>
      </Box>

      {passengerProfile?.personaType?.personalDetailOptions.map((radio) => (
        <AppRadioButton
          key={radio.firstLabel + radio.secondLabel}
          firstLabel={radio.firstLabel}
          secondLabel={radio.secondLabel}
        />
      ))}

      <Divider sx={{ borderColor: "#FFFFFF59", width: "100%", mt: "24px" }} />
      <Typography
        sx={{
          pt: "22px",
          fontSize: "16px",
          fontWeight: 400,
          color: "#EADCFA",
        }}
        mb={1}
      >
        Travel Details
      </Typography>

      {passengerProfile?.personaType?.travelOptions.map((radio) => (
        <AppRadioButton
          key={radio.firstLabel + radio.secondLabel}
          firstLabel={radio.firstLabel}
          secondLabel={radio.firstLabel === 'Miles Balance'
            ? formatNumber(radio.secondLabel as number)
            : radio.secondLabel}
        />
      ))}

      {recentFlightDetails && isPreviewLoaded && (
        <>
          <Divider sx={{ borderColor: "#FFFFFF59", width: "100%", mt: "24px" }} />
          <Typography
            sx={{
              pt: "22px",
              fontSize: "16px",
              fontWeight: 400,
              color: "#EADCFA",
            }}
            mb={1}
          >
            Recent Flight Details
          </Typography>

          {recentFlightDetails.map((detail) => (
            <AppRadioButton
              key={detail.firstLabel}
              firstLabel={detail.firstLabel}
              secondLabel={detail.secondLabel}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default TravelDetail;
