import {Box, Typography} from "@mui/material";
import {useState} from "react";
import {userInfo} from "../constants/Constants";
import {Ancillary} from "../types/Ancillary";
import AirlineList from "./AirlineList";
import EmailNotification from "./EmailNotification";
import SelectAirlineHeader from "./SelectAirlineHeader";
import UserInfo from "./UserInfo";
import {usePassenger} from "../context/PassengerContext";
import {boolean, string} from "yup";

interface SelectAirlineProps {
    ancillaries: Ancillary[],
    setStep: (value: (((prevState: string) => string) | string)) => void,
    setIsComplete: (value: (((prevState: { isLoading: boolean; isDone: boolean }) => {
        isLoading: boolean;
        isDone: boolean
    }) | { isLoading: boolean; isDone: boolean })) => void,
    setPreviewData: (value: any) => void,
    setIsPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectAirline: React.FC<SelectAirlineProps> = ({
                                                         ancillaries,
                                                         setStep,
                                                         setIsComplete,
                                                         setPreviewData,
                                                         setIsPreviewLoaded
                                                     }) => {
    const [selectedAncillaries, setSelectedAncillaries] = useState<string[]>([]);
    const {passengerProfile, setPassengerProfile} = usePassenger();
    const [isConfirm, setIsConfirm] = useState(false);

    const toggleAncillary = (ancillaryName: string, value: Ancillary) => {
        const isAlreadySelected = selectedAncillaries?.includes(ancillaryName);
        const selectedAncillariesArray = Array.isArray(
            passengerProfile.selectedAncillaries
        )
            ? passengerProfile.selectedAncillaries
            : [];

        // Calculate the total price of currently selected ancillaries
        const currentTotalPrice = selectedAncillariesArray.reduce(
            (total, ancillary) => total + ancillary.price,
            0
        );

        // Calculate the new total price
        const newTotalPrice = isAlreadySelected
            ? currentTotalPrice - value.price
            : currentTotalPrice + value.price;

        // Check if the new total price exceeds the user's miles balance
        if (newTotalPrice > passengerProfile.milesAccountBalance + passengerProfile.milesEarnt) {
            // If it does, don't allow the selection
            return;
        }

        // If there are enough miles, proceed with the selection
        setSelectedAncillaries((prev) =>
            isAlreadySelected
                ? prev?.filter((name) => name !== ancillaryName)
                : [...prev, ancillaryName]
        );

        setPassengerProfile({
            ...passengerProfile,
            selectedAncillaries: isAlreadySelected
                ? passengerProfile.selectedAncillaries?.filter(
                    (item) => item.name !== ancillaryName
                )
                : [...selectedAncillariesArray, value],
        });
    };

    return (
        <Box
            sx={{
                bgcolor: "#FFFFFFCC",
                borderRadius: "20px",
                width: "100%",
                pt: "15px",
                pr: "12px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <SelectAirlineHeader
                setStep={setStep}
                setIsComplete={setIsComplete}
                setPreviewData={setPreviewData}
                setIsPreviewLoaded={setIsPreviewLoaded}
                isConfirm={isConfirm}
                setIsConfirm={setIsConfirm}
                setPassengerProfile={setPassengerProfile}
            />
            <Box
                sx={{
                    mt: "10px",
                    bgcolor: "white",
                    width: "100%",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "20px",
                    px: "12px",
                }}
            >
                <Typography
                    sx={{fontSize: "16px", fontWeight: 400, pt: "16px", pl: "44px"}}
                >
                    Select Your Extras
                </Typography>
                <UserInfo userInfo={userInfo}/>
                <EmailNotification/>
                <AirlineList
                    ancillaries={ancillaries}
                    toggleAncillary={toggleAncillary}
                    selectedAncillaries={selectedAncillaries}
                    isConfirm={isConfirm}
                    setIsConfirm={setIsConfirm}
                />
            </Box>
        </Box>
    );
};

export default SelectAirline;