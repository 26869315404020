import { Autocomplete, Paper, TextField, Tooltip } from "@mui/material";
import React from "react";

interface AppSelectorProps {
  options: string[];
  placeHolder: string;
  value: string;
  onChange: (value: string) => void;
  disable: boolean;
  error: string;
  touched?: boolean; 
  onBlur?: any
}

const AppSelector: React.FC<AppSelectorProps> = ({
  options,
  placeHolder,
  value,
  onChange,
  onBlur,
  disable,
  error,
  touched = false, 
}) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      value={value}
      disabled={disable}
      onBlur={onBlur && onBlur}
      onChange={(_event, newValue) => onChange(newValue || "")}
      getOptionDisabled={(option: string) => option === options[0]}
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          mt: "16px",
          backgroundColor: "#36303D",
          height: "50px",
          borderRadius: "10px",
          color: "white",
          border: error && touched ? ".1px solid #C3A8E5" : undefined,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: error && touched ? "#C3A8E5" : "#36303D",
          },
          "&:hover fieldset": {
            borderColor: "#C3A8E5",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#C3A8E5",
          },
        },
        "& .MuiAutocomplete-popupIndicator": {
          color: "white",
        },
        "& .MuiAutocomplete-clearIndicator": {
          color: "white",
        },
      }}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            bgcolor: "#36303D",
            backgroundImage: "none",
            color: "white",
            borderRadius: "8px",
            zIndex: 2,
          }}
        >
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        <Tooltip
          title={touched && error ? error : ""}
          open={!!(touched && error)}
          arrow
          sx={{ zIndex: 1 }}
          placement="bottom-end"
        >
          <TextField
            {...params}
            placeholder={placeHolder}
            InputLabelProps={{
              sx: {
                mt: "-8px",
                color: "white",
                "&.Mui-focused": {
                  color: "white",
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                color: "white",
              },
            }}
          />
        </Tooltip>
      )}
    />
  );
};

export default AppSelector;
