import { Box, Checkbox, SxProps, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Theme } from "@emotion/react";
import { useStep } from "../context/StepContext";

interface AppCheckBoxProps {
  label: string;
  styles?: SxProps<Theme>;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const AppCheckbox: React.FC<AppCheckBoxProps> = ({
  label,
  styles,
  checked,
  onChange,
}) => {
  const combinedStyles: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    ...styles,
  };
  const { step } = useStep();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <Box sx={combinedStyles}>
      <Checkbox
        checked={checked}
        disabled={step !== "custom"}
        onChange={handleChange}
        icon={
          <span
            style={{
              width: "16px",
              height: "16px",
              borderRadius: 4,
              marginRight: "4px",
              backgroundColor: "#5B5560",
            }}
          />
        }
        checkedIcon={
          <span
            style={{
              width: "16px",
              height: "16px",
              borderRadius: 4,
              marginRight: "4px",
              backgroundColor: "#BB1BEF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckIcon sx={{ color: "#fff", fontSize: "14px" }} />
          </span>
        }
        sx={{
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "16px",
          },
        }}
      />
      <Typography
        sx={{ color: "#A4A4A4", fontSize: { xs: "11px", lg: "14px" } }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default AppCheckbox;
