import { PassengerProfile } from "../types/Passenger";

export const GenderOptions = ["Gender", "Male", "Female"];
export const FlightOptions = ["Traveller Group", "Solo", "Couple", "Family","Group"];
export const travelPurposeOptions = [
  "Purpose of Travel",
  "Work",
  "Leisure",
  "Education",
  "Visiting Family and Relatives (VFR)",
];
export const loyaltyStatusOptions = [
  "Loyalty Status",
  "Non-member",
  "Bronze",
  "Silver",
  "Gold",
  "Platinum",
];
export const services = [
  "Priority Boarding",
  "Seat Selection",
  "Priority Check-In",
  "Extra Baggage",
  "Advance Check-In",
  "Extra Legroom",
  "Sports Equipment",
  "Lounge Access",
  "In-Flight Wifi",
  "Extra Carry-On",
];

export const recentFlights = [
  { from: "DXB", to: "JFK", distance: 6831, title:"Long-Haul" },
  { from: "JFK", to: "LAX", distance: 2469, title:"Medium-Haul" },
  { from: "LHR", to: "OSL", distance: 750, title:"Short-Haul" },
];

export const loyaltyStatuses = [
  { from: "", to: "Non-member" },
  { from: "", to: "Bronze" },
  { from: "", to: "Silver" },
  { from: "", to: "Gold" },
  { from: "", to: "Platinum" },
];

export const fareClasses = [
  { from: "", to: "Economy" },
  { from: "", to: "Premium Economy" },
  { from: "", to: "Business" },
  { from: "", to: "First" },
];


export const flightDistanceMultiplier = {
    "DXB-JFK": 4200,
    "JFK-LAX": 2475,
    "LHR-OSL": 750,
  }

export const loyaltyStatusMultiplier = {
    "Non-member": 1,
    "Bronze": 1,
    "Silver": 2,
    "Gold": 3,
    "Platinum": 4,
  }


export const fareClassMultiplier = {
    "Economy": 1,
    "Premium Economy": 1.5,
    "Business": 2,
    "First": 3,
  }

export const airlines = [
  {
    title: "Seat Selection",
    miles: "12345 Miles",
  },
  {
    title: "Priority Boarding",
    miles: "12345 Miles",
  },
  {
    title: "Extra Legroom",
    miles: "12345 Miles",
  },
  {
    title: "Auto Check-In",
    miles: "12345 Miles",
  },
  {
    title: "Fast-track Security",
    miles: "12345 Miles",
  },
];

export const userInfo = {
  name: "Etihad Guest",
  email: "no-reply@email.etihadguest.com",
  to: "To me",
};

export const dummyAncillary = [
  {
    name: "Priority Boarding",
    imageUrl:
      "https://www.travelandleisure.com/thmb/9cQ_FktRwVJQUiTQtpq1-WeF7kc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/emirates-business-class-dining-EMIRATESBIZ0322-e8414f0893f640929ea0cb2d286d8e61.jpg",
    price: 1210,
  },
  {
    name: "Extra Legroom",
    imageUrl:
      "https://www.travelandleisure.com/thmb/9cQ_FktRwVJQUiTQtpq1-WeF7kc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/emirates-business-class-dining-EMIRATESBIZ0322-e8414f0893f640929ea0cb2d286d8e61.jpg",
    price: 1232,
  },
  {
    name: "In-flight Meal",
    imageUrl:
      "https://www.travelandleisure.com/thmb/9cQ_FktRwVJQUiTQtpq1-WeF7kc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/emirates-business-class-dining-EMIRATESBIZ0322-e8414f0893f640929ea0cb2d286d8e61.jpg",
    price: 7830,
  },
  {
    name: "In-flight Meal",
    imageUrl:
      "https://www.travelandleisure.com/thmb/9cQ_FktRwVJQUiTQtpq1-WeF7kc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/emirates-business-class-dining-EMIRATESBIZ0322-e8414f0893f640929ea0cb2d286d8e61.jpg",
    price: 2320,
  },
  {
    name: "In-flight Meal",
    imageUrl:
      "https://www.travelandleisure.com/thmb/9cQ_FktRwVJQUiTQtpq1-WeF7kc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/emirates-business-class-dining-EMIRATESBIZ0322-e8414f0893f640929ea0cb2d286d8e61.jpg",
    price: 9070,
  },
];

export const ancillaryImagesEmails: { [key: string]: string } = {
  "Charity Donation": "https://i.ibb.co/TRq8PDF/Charity-Donation.png",
  "Extra Baggage": "https://i.ibb.co/wMC6pDx/Extra-Baggage.png",
  "In-Flight Beverage": "https://i.ibb.co/pZdPjTF/In-Flight-Beverage.jpg",
  "In-Flight Wifi": "https://i.ibb.co/dcTFPBR/In-Flight-Wi-Fi.jpg",
  "Lounge Access": "https://i.ibb.co/JcTBMmD/Lounge-Access.png",
  "Priority Boarding": "https://i.ibb.co/8cgKm22/Priority-Boarding.png",
  "Priority Check-In": "https://i.ibb.co/JskTbbC/Priority-Check-In.png",
  "Seat Selection": "https://i.ibb.co/ZfmnQQ3/Seat-Selection.png",
  "Extra Legroom": "https://i.ibb.co/gVJTj6B/Extra-Legroom.png",
  "Advance Check-In": "https://i.ibb.co/BgDtW4B/Advance-Check-In-1.png",
  "Flex Fares": "https://i.ibb.co/mFj7Wr8/Flex-Fares.png",
  "Auto Check-In": "https://i.ibb.co/g77tJkC/Auto-Check-In.png",
  "Fare Price Freeze": "https://i.ibb.co/phfwqqb/Fare-Price-Freeze.png",
  "Sports Equipment": "https://i.ibb.co/ZHJZMyw/Sports-Equipment.png",
  "Fasttrack Security": "https://i.ibb.co/BPzyhpN/Fast-track-Security.png",
  "Gourmet Meal": "https://i.ibb.co/XVjbXh4/Gourmet-Meal.png",
  "Airport Transfer": "https://i.ibb.co/kS3fTvG/Airport-Transfer.png",
  "Additional Carry-On Bag": "https://i.ibb.co/jZhy4qf/Additional-Carry-On-Bag.png",
  "Flight Carbon Offset": "https://i.ibb.co/2yMqM6j/Flight-Carbon-Offset.png",
  "Chauffeur Transfer": "https://i.ibb.co/CmJGmd0/Chauffeur-Transfer.png",
  "15% Off Selected Duty Free Stores": "https://i.ibb.co/JpfYXLq/15-Off-Selected-Duty-Free-Stores.png",
  "Fast-Track Immigration": "https://i.ibb.co/SQStPCn/Fast-Track-Immigration.png",
  "Priority Baggage Delivery": "https://i.ibb.co/4SGJ4DC/Priority-Baggage-Delivery.png",
  "Guaranteed Seat on Next Booking": "https://i.ibb.co/mqh3zns/Guaranteed-Seat-on-Next-Booking.png",
  "Home Check-In": "https://i.ibb.co/LvFcqHW/Home-Check-In.png",
  "10% Discount on Visitor Bookings": "https://i.ibb.co/8Y1d8G0/10-Discount-on-Viator-Bookings.png",
  "15% Discount on Holiday Inn Stays": "https://i.ibb.co/7gMHXKB/15-Discount-on-Holiday-Inn-Stays.png",
  "15% Discount on Radisson Blu Stays": "https://i.ibb.co/S73Gsnv/5-Discount-on-Radisson-Blu-Stays.png",
  "15% Discount on Pullman Hotel Stays": "https://i.ibb.co/VxwkHcZ/15-Discount-on-Pullman-Hotel-Stays.png",
  "15% Discount on Value Sedan Hire": "https://i.ibb.co/GTsgX5b/15-Discount-on-Value-Sedan-Hire.png",
  "15% Discount on Value SUV Hire": "https://i.ibb.co/QXt96Ts/15-Discount-on-Value-SUV-Hire.png",
  "15% Discount on Luxury SUV Hire": "https://i.ibb.co/Q6MBDKs/15-Discount-on-Luxury-SUV-Hire.png",
  "15% Discount on Luxury Sedan Hire": "https://i.ibb.co/g7sy9cH/15-Discount-on-Luxury-Sedan-Hire.png",
  "10% Discount on Viator Bookings": "https://i.ibb.co/8Y1d8G0/10-Discount-on-Viator-Bookings.png",
  "Travel Insurance": "https://i.ibb.co/ZTgSXm6/Travel-Insurance.png"
};

export const ancillaryImages: { [key: string]: string } = {
  "Charity Donation": require("../assets/images/CharityDonation.png"),
  "Extra Baggage": require("../assets/images/ExtraLuggage.png"),
  "In-Flight Beverage": require("../assets/images/In-FlightBeverage.avif"),
  "In-Flight Wifi": require("../assets/images/In-FlightWi-Fi.jpg"),
  "Lounge Access": require("../assets/images/LoungeAccess.png"),
  "Priority Boarding": require("../assets/images/PriorityBoarding.png"),
  "Priority Check-In": require("../assets/images/PriorityCheck-In.png"),
  "Seat Selection": require("../assets/images/SeatSelection.png"),
  "Extra Legroom": require("../assets/images/Extra Legroom.png"),
  "Advance Check-In": require("../assets/images/Advance Check-In-1.png"),
  "Flex Fares": require("../assets/images/Flex Fares.png"),
  "Auto Check-In": require("../assets/images/Auto Check-In.png"),
  "Fare Price Freeze": require("../assets/images/Fare Price Freeze.png"),
  "Sports Equipment": require("../assets/images/Sports Equipment.png"),
  "Fasttrack Security": require("../assets/images/Fast-track Security.png"),
  // "Travel Insurance": require("../assets/images/Insurance"),
  "Gourmet Meal": require("../assets/images/Gourmet Meal_.png"),
  "Airport Transfer": require("../assets/images/Airport Transfer.png"),
  "Additional Carry-On Bag": require("../assets/images/Additional Carry-On Bag.png"),
  "Flight Carbon Offset": require("../assets/images/Flight Carbon Offset.png"),
  "Chauffeur Transfer": require("../assets/images/Chauffeur Transfer.png"),
  "15% Off Selected Duty Free Stores": require("../assets/images/15_ Off Selected Duty Free Stores.png"),
  "Fast-Track Immigration": require("../assets/images/Fast-Track Immigration.png"),
  "Priority Baggage Delivery": require("../assets/images/Priority Baggage Delivery.png"),
  "Guaranteed Seat on Next Booking": require("../assets/images/Guaranteed Seat on Next Booking.png"),
  "Home Check-In": require("../assets/images/Home Check-In.png"),
  "10% Discount on Visitor Bookings": require("../assets/images/10_ Discount on Viator Bookings.png"),
  "15% Discount on Holiday Inn Stays": require("../assets/images/15_ Discount on Holiday Inn Stays.png"),
  "15% Discount on Radisson Blu Stays": require("../assets/images/5_ Discount on Radisson Blu Stays.png"),
  "15% Discount on Pullman Hotel Stays": require("../assets/images/15_ Discount on Pullman Hotel Stays.png"),
  "15% Discount on Value Sedan Hire": require("../assets/images/15_ Discount on Value Sedan Hire.png"),
  "15% Discount on Value SUV Hire": require("../assets/images/15_ Discount on Value SUV Hire.png"),
  "15% Discount on Luxury SUV Hire": require("../assets/images/15_ Discount on Luxury SUV Hire.png"),
  "15% Discount on Luxury Sedan Hire": require("../assets/images/15_ Discount on Luxury Sedan Hire.png"),
  "10% Discount on Viator Bookings": require("../assets/images/10_ Discount on Viator Bookings.png"),
  "Travel Insurance": require("../assets/images/Travel Insurance.png")
};

export const initialProfile: PassengerProfile = {
  email: "",
  age: null,
  gender: "",
  services: [],
  purposeOfTravel: "",
  loyaltyStatus: "Non-member",
  milesAccountBalance: 0,
  milesEarnt: 0,
  mostPurchasedAncillaries: [],
  selectedAncillaries: [],
  stopover: false,
  travelGroup: "Select",
  personaType: undefined,
  recentFlight: {
    flight: {
      from: "DXB",
      to: "JFK",
    },
    class: "Economy",
    loyaltyNumber: "",
    loyaltyStatus: { from: "", to: "" }
  },
};

export const personaTypes = [
  {
    label: "Business Traveler",
    imagePath: require("../assets/images/briefcase.png"),
    age: 47,
    gender: "Male",
    purposeOfTravel: "Work",
    loyaltyStatus: "Gold",
    stopover: false,
    travelGroup: "Solo",
    services: ["Lounge Access", "Priority Boarding", "Flex Fares"],
    milesAccountBalance: 68000,
    personalDetailOptions: [
      {
        firstLabel: "Age",
        secondLabel: "47",
        value: "47",
      },
      {
        firstLabel: "Gender",
        secondLabel: "Male",
        value: "Male",
      },
    ],
    travelOptions: [
      {
        firstLabel: "Travel Purpose",
        secondLabel: "Work",
        value: "Work",
      },
      /*{
        firstLabel: "Loyalty Status",
        secondLabel: "Gold",
        value: "Gold",
      },*/
      {
        firstLabel: "Miles Balance",
        secondLabel: 68000,
        value: 68000,
      },
      {
        firstLabel: "Travel Group",
        secondLabel: "Solo",
        value: "Solo",
      },
      { firstLabel: "Stopover", secondLabel: "No", value: "No" },
      {
        firstLabel: "Most Purchased Ancillaries",
        secondLabel: "Lounge Access, Priority Boarding, Flex Fares",
        value: "Lounge Access, Priority Boarding, Flex Fares",
      },
    ],
  },
  {
    travelGroup: "Solo",
    stopover: false,
    label: "International Student",
    imagePath: require("../assets/images/glob.png"),
    age: 19,
    gender: "Female",
    purposeOfTravel: "Education",
    loyaltyStatus: "Non-member",
    services: ["Extra Baggage", "In-Flight Wifi", "Seat Selection"],
    milesAccountBalance: 0,
    personalDetailOptions: [
      {
        firstLabel: "Age",
        secondLabel: "19",
        value: "19",
      },
      {
        firstLabel: "Gender",
        secondLabel: "Female",
        value: "Female",
      },
    ],
    travelOptions: [
      {
        firstLabel: "Travel Purpose",
        secondLabel: "Education",
        value: "Education",
      },
      /*{
        firstLabel: "Loyalty Status",
        secondLabel: "Non-member",
        value: "Non-member",
      },*/
      {
        firstLabel: "Miles Balance",
        secondLabel: 0,
        value: 0,
      },
      {
        firstLabel: "Travel Group",
        secondLabel: "Solo",
        value: "Solo",
      },
      { firstLabel: "Stopover", secondLabel: "Yes", value: "Yes" },
      {
        firstLabel: "Most Purchased Ancillaries",
        secondLabel: "Extra Baggage, In-Flight Wifi, Seat Selection",
        value: "Extra Baggage, In-Flight Wifi, Seat Selection",
      },
    ],
  },
  {
    travelGroup: "Family",
    stopover: true,
    services: ["Extra Carry-On", "Priority Check-In", "Seat Selection"],
    label: "Value Family Traveler with Infant",
    imagePath: require("../assets/images/users.png"),
    age: 33,
    gender: "Male",
    purposeOfTravel: "Visiting Family and Relatives (VFR)",
    loyaltyStatus: "Silver",
    milesAccountBalance: 16000,
    personalDetailOptions: [
      {
        firstLabel: "Age",
        secondLabel: "33",
        value: "33",
      },
      {
        firstLabel: "Gender",
        secondLabel: "Male",
        value: "Male",
      },
    ],
    travelOptions: [
      {
        firstLabel: "Travel Purpose",
        secondLabel: "Visiting Family and Relatives (VFR)",
        value: "Visiting Family and Relatives (VFR)",
      },
      /*{
        firstLabel: "Loyalty Status",
        secondLabel: "Silver",
        value: "Silver",
      },*/
      {
        firstLabel: "Miles Balance",
        secondLabel: 16000,
        value: 16000,
      },
      {
        firstLabel: "Travel Group",
        secondLabel: "Family",
        value: "Family",
      },
      { firstLabel: "Stopover", secondLabel: "Yes", value: "Yes" },
      {
        firstLabel: "Most Purchased Ancillaries",
        secondLabel: "Extra Carry-On, Priority Check-In, Seat Selection (Equipped with Bassinet)",
        value: "Extra Carry-On, Priority Check-In, Seat Selection (Equipped with Bassinet)",
      },
    ],
  },
  {
    travelGroup: "Couple",
    stopover: true,
    label: "Premium Leisure Traveler",
    imagePath: require("../assets/images/vacation.png"),
    services: ["Travel Insurance", "Gourmet Meal", "Lounge Access"],
    age: 27,
    gender: "Male",
    purposeOfTravel: "Leisure",
    loyaltyStatus: "Gold",
    milesAccountBalance: 32500,
    personalDetailOptions: [
      {
        firstLabel: "Age",
        secondLabel: "27",
        value: "27",
      },
      {
        firstLabel: "Gender",
        secondLabel: "Male",
        value: "Male",
      },
    ],
    travelOptions: [
      {
        firstLabel: "Travel Purpose",
        secondLabel: "Leisure",
        value: "Leisure",
      },
      /*{
        firstLabel: "Loyalty Status",
        secondLabel: "Gold",
        value: "Gold",
      },*/
      {
        firstLabel: "Miles Balance",
        secondLabel: 32500,
        value: 32500,
      },
      {
        firstLabel: "Travel Group",
        secondLabel: "Couple",
        value: "Couple",
      },
      { firstLabel: "Stopover", secondLabel: "No", value: "No" },
      {
        firstLabel: "Most Purchased Ancillaries",
        secondLabel: "Travel Insurance, Gourmet Meal, Lounge Access",
        value: "Travel Insurance, Gourmet Meal, Lounge Access",
      },
    ],
  },
];

export const infoDetails = {
  defaultPersona: {
    heading: "Default Passenger Personas",
    detail: "See how our product in action with pre-set passenger persona characteristics.",
  },
  age: {
    heading: "Create a Persona",
    detail: "Needs for travellers change on various factors such as age, purpose of travel, along with their past selection history. Customise these here and see how suggestions change.",
  },
  services: {
    heading: "Most Purchased Ancillaries",
    detail: "Taken from travel history, your past use of ancillaries increases the chance of recommendation by us for your next booking.",
  },
  stopover: {
    heading: "Add a Stopover",
    detail: "A new trend in long-haul travel, you can explore the city you are transiting through. If we know a customer likes to stopover, we can suggest stopover-specific benefits to enjoy on the ground.",
  },
  travelGroup: {
    heading: "Traveller Group",
    detail: "Passenger needs change depending on who you travel with. Our recommendations help make the traveller journey as smooth as possible.",
  },
  recentFlights: {
    heading: "Flight Selection",
    detail: "Needs for comfort, baggage and extras change depending on the length, origin and destination of travel. Use your miles to elevate your experience.",
  },
  milesEarnt: {
    heading: "Miles Earnings",
    detail: "Combine the points currently in your loyalty account with those you’ve just earnt from your flight to reserve benefits for a future booking.",
  },
  loyaltyStatus: {
    heading: "Fare Class & Loyalty Status",
    detail: "Both class of travel and your loyalty program tier adds a multiplier to the number of points you earn, but also what we suggest you. In premium cabins, some ancillaries come as standard - so we have to think outside the box."
  },
  milesAccountBalance: {
    heading: "Account Miles Balance",
    detail: "How many loyalty points do you have in your account? Rather than let them expire or become forgotten, our algorithm can allow you to make the most of your points balance.",
  },
};
