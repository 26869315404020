// StepContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface StepContextProps {
  step: string;
  setStep: React.Dispatch<React.SetStateAction<string>>;
}

const StepContext = createContext<StepContextProps | undefined>(undefined);

export const StepProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [step, setStep] = useState<string>('custom');

  return (
    <StepContext.Provider value={{ step, setStep }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStep = (): StepContextProps => {
  const context = useContext(StepContext);
  if (context === undefined) {
    throw new Error('useStep must be used within a StepProvider');
  }
  return context;
};
