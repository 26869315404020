import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import AppButton from "./Button";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = [
  { name: "Home", url: "https://www.arcube.org" },
  { name: "Press", url: "https://www.arcube.org/press" },
  { name: "Contact us", url: "https://www.arcube.org/contact-us" }
];

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [selectedButton, setSelectedButton] =
    React.useState<String>("Contact us");
  const buttonStyle = {
    color: "#fff",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px",
    textTransform: "none",
    padding: "8px 16px",
      ml: "8px"
  };
  const selectedButtonStyle = {
    background: "linear-gradient(97.92deg, #A107EA 0%, #5000B5 99.93%)",
    padding: "8px 16px",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: 600,
      ml: "16px"
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", py: "24px" }}>
      <a href="https://www.arcube.org/demo">
        <img
          src={require("../assets/images/logo.png")}
          alt="Logo"
          style={{
            width: 200,
            height: 40,
            marginRight: 10,
            objectFit: "contain",
          }}
        />
      </a>
      <Divider sx={{ borderColor: "white", mt: "10px" }} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              component="a"
              href={item.url}
              sx={{
                background: selectedButton === item.name
                  ? "linear-gradient(97.92deg, #A107EA 0%, #5000B5 99.93%)"
                  : "transparent",
                textAlign: "center",
                padding: "16px",
              }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          position:'absolute',
          backgroundColor: "#1f182800",
          boxShadow: "none",
          px: { md: "64px" },
          pt: { xs: "16px", md: "24px" },
          pb: '24px',
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <a href="https://www.arcube.org/demo">
              <img
                src={require("../assets/images/logo.png")}
                alt="Logo"
                style={{width: 220, height: 57.5, marginRight: 10}}
              />
            </a>
          </Box>
          <Box sx={{display: {xs: "none", sm: "flex"}}}>
            {navItems.map((item) => (
              <a key={item.name} href={item.url} style={{ textDecoration: 'none' }}>
                <AppButton
                  variant={selectedButton === item.name ? "contained" : "text"}
                  styles={
                    selectedButton === item.name ? selectedButtonStyle : buttonStyle
                  }
                >
                  {item.name}
                </AppButton>
              </a>
            ))}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ fill: "#978EA1" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#1F1828",
              color: "white",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
