// components/Header.tsx
import React from 'react';
import { Typography } from "@mui/material";

interface HeaderProps {
  text: string;
}

const Header: React.FC<HeaderProps> = ({ text }) => (
  <Typography
    sx={{
      fontSize: { xs: "48px",lg:"52px" },
      fontWeight: 700,
      color:'#fff',
      lineHeight: { xs: "60px", lg: "121px" },
      alignSelf: "center",
      mt: { xs: "45px", lg: "90px" },
      background:
        "linear-gradient(99.42deg, rgba(255, 255, 255, 0.35) 44.79%, rgba(255, 255, 255, 0) 235.93%)",
      WebkitBackgroundClip: "text",
      textAlign: "center",
    }}
  >
    {text}
  </Typography>
);

export default Header;
