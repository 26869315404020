import * as Yup from "yup";

export const profileSchema = Yup.object().shape({
  age: Yup.number()
    .required("Age is required")
    .min(1, "Age must be at least 1")
    .max(120, "Age must be at most 120"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string(),
  purposeOfTravel: Yup.string().required("Purpose of travel is required"),
  milesBalance: Yup.number()
    .required("Miles balance is required")
    .min(0, "Miles balance cannot be negative"),
  services: Yup.array()
    .of(Yup.string())
    .max(3, "You can select up to three services"),
  stopover: Yup.boolean().required("Stopover information is required"),
  travelGroup: Yup.mixed()
  .test('is-valid-travel-group', 'Please select a valid travel group', function(value) {
    return value !== 'Select' && value !== 0 && value !== '0' && value !== "" && value !== undefined;
  })
});

export const recentFlightSchema = Yup.object().shape({
  recentFlight: Yup.object().shape({
    flight: Yup.mixed().test(
      'flight-string-or-object',
      'Flight must be either a string or an object with from and to fields',
      function(value) {
        if (typeof value === 'string') {
          return true;
        }
        if (typeof value === 'object' && value !== null) {
          return Yup.object().shape({
            from: Yup.string().required("Departure location is required"),
            to: Yup.string().required("Destination is required"),
          }).isValidSync(value);
        }
        return false;
      }
    ).required("Flight information is required"),
    class: Yup.string().required("Travel class is required"),
    loyaltyNumber: Yup.string(),
    loyaltyStatus: Yup.string(),
  }),
});

export const validationPurchaseSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
