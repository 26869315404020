import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Typography } from "@mui/material";

interface UserInfoProps {
  userInfo: {
    name: string;
    email: string;
    to: string;
  };
}

const UserInfo: React.FC<UserInfoProps> = ({ userInfo }) => {
  return (
    <Box sx={{ display: "flex", gap: "9px", pl: "8px", mt: "8px" }}>
      <Avatar sx={{ width: {xs:"27px",xl:'35px'}, height: {xs:"27px",xl:'35px'}, bgcolor: "#CAA0FF" }}>
        <PersonIcon sx={{fontSize:{xs:'22px',xl:'28px',fill:'#292E37'}}}/>
      </Avatar>
      <Box>
        <Typography
          sx={{
            display: "flex",
            fontSize: {xs:"10px",xl:'14px'},
            fontWeight: 700,
            alignItems: "center",
            gap: "4px",
          }}
        >
          {userInfo.name}
          <Typography sx={{ fontWeight: 400, fontSize: {xs:"10px",xl:'14px'} }}>
            {`<${userInfo.email}>`}
          </Typography>
        </Typography>
        <Typography sx={{ fontSize: {xs:"10px",xl:'14px'} }}>{userInfo.to}</Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
