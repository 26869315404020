import {
    Button as MUIButton,
    ButtonProps as MUIButtonProps,
    SxProps,
    Theme
} from "@mui/material";
import React, { ReactNode } from "react";

interface AppButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  variant?: MUIButtonProps["variant"];
  children: ReactNode;
  styles?: SxProps<Theme>;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
}

const AppButton: React.FC<AppButtonProps> = ({
  children,
  onClick,
  endIcon,
  variant = "contained",
  fullWidth = false,
  styles,
  startIcon,
  disabled,
}) => {

  const combinedStyles: SxProps<Theme> = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    height: "40px",
    borderRadius: "10px",
    ...styles,
  };

  return (
    <MUIButton
      disabled={disabled}
      variant={variant}
      color="primary"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      sx={combinedStyles}
    >
      {children}
    </MUIButton>
  );
};

export default AppButton;
