// components/Preview.tsx
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ancillaryImages, dummyAncillary, services } from "../constants/Constants";
import { Ancillary } from "../types/Ancillary";
import AppLoader from "./AppLoader";
import Header from "./Header";
import SelectAirline from "./SelectAirline";
import { useStep } from "../context/StepContext";
import { useLoading } from "../context/LoadingContext";
import { usePassenger } from "../context/PassengerContext";

interface PreviewProps {
  setIsPreviewLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviewLoaded: boolean;
}

const Preview: React.FC<PreviewProps> = ( { isPreviewLoaded, setIsPreviewLoaded } ) => {
  const [isComplete, setIsComplete] = useState({
    isLoading: false,
    isDone: false,
  });
  const { isLoading, setIsLoading } = useLoading();
  const { step, setStep } = useStep();
  const [previewData, setPreviewData] = useState<any>(null);
  const [ancillaries, setAncillaries] = useState<Ancillary[]>([]);
  const { passengerProfile } = usePassenger();

  const fetchPreviewData = async (): Promise<void> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const updatedServices = passengerProfile.services.map(service => 
      service === "Extra Carry-On" ? "Additional Carry-On Bag" : service
    );

    const updatedTravelPurpose = passengerProfile.purposeOfTravel === "Visiting Family and Relatives (VFR)" 
    ? "VFR" 
    : passengerProfile.purposeOfTravel;

    const payload = {
      age: passengerProfile.age,
      gender: passengerProfile.gender,
      travelPurpose: updatedTravelPurpose,
      loyaltyStatus: passengerProfile.loyaltyStatus,
      milesBalance: passengerProfile.milesAccountBalance,
      stopover: passengerProfile.stopover,
      travelClass: passengerProfile.recentFlight.class.trim(),
      services: updatedServices,
      recentFlight: typeof passengerProfile.recentFlight.flight === "object"
        ? `${passengerProfile.recentFlight.flight.from}-${passengerProfile.recentFlight.flight.to}`
        : (passengerProfile.recentFlight.flight as string).replace(/\s/g, '-'),
      travelGroup: passengerProfile.travelGroup,
    };

    console.log("payload", payload);

    const raw = JSON.stringify(payload);

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://nrt09zgdoi.execute-api.eu-west-1.amazonaws.com/ProtoAPI/getRecommendations",
        requestOptions
      );
      const result = await response.json();
      console.log("result", result);

      // Parse the body of the response, which is a string
      const parsedBody = JSON.parse(result.body);

      // Convert the object to an array of ancillaries
      const parsedAncillaries = Object.entries(parsedBody).map(([name, price]) => ({
        name,
        price: price as number,
      }));
      console.log("parsedAncillaries", parsedAncillaries);

      const updatedAncillaries = parsedAncillaries.map((item) => {
        const matchingImageKey = Object.keys(ancillaryImages).find((key) =>
          key.toLowerCase().includes(item.name.toLowerCase())
        );
        return {
          ...item,
          imageUrl: matchingImageKey
            ? ancillaryImages[matchingImageKey]
            : require("../assets/images/Placeholder.png"),
        };
      });

      setAncillaries(updatedAncillaries);
      setPreviewData(result);
      console.log(updatedAncillaries);
      setIsLoading(false);
    } catch (error) {
      console.error("Error connecting to recommendation endpoint", error);
      setAncillaries(dummyAncillary);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading && step === "loadPreview") {
      fetchPreviewData();
    }
  }, [isLoading, step]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background:
          "radial-gradient(68.92% 48.55% at 50% 44.61%, #403451 0%, #1F1828 66.51%)",
        padding: { xs: "20px", md: "0px" },
        boxSizing: "border-box",
        minHeight: "100vh",
      }}
    >
      <Header text="" />

      {isLoading ? (
        <>
          <AppLoader />
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "24px" },
              color: "white",
              fontWeight: 600,
              alignSelf: "center",
              textAlign: "center",
              mt: "35px",
            }}
          >
            Loading Preview
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              color: "white",
              fontWeight: 400,
              alignSelf: "center",
              textAlign: "center",
              mt: "10px",
            }}
          >
            Ancillaries are Being Dynamically Priced… 🤫
          </Typography>
        </>
      ) : !isLoading && step === "loadPreview" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: { xs: "0px", lg: "45px" },
            mr: { xs: "0px", lg: "24px" },
            mt: { lg: "58px" },
          }}
        >
          <SelectAirline ancillaries={ancillaries} setStep={setStep} setIsComplete={setIsComplete} setPreviewData={setPreviewData} setIsPreviewLoaded={setIsPreviewLoaded}/>
        </Box>
      ) : !isLoading && step === "complete" ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          mt={{ xs: "100px", md: "232px" }}
          flexDirection="column"
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "24px" },
              color: "white",
              fontWeight: 600,
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            Purchase Confirmed ✅
          </Typography>

          <Typography
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              color: "white",
              fontWeight: 300,
              alignSelf: "center",
              textAlign: "center",
              mt: "35px",
              maxWidth: "336px",
            }}
          >
            Check Your Inbox 📧
          </Typography>

          <Button
            variant="text"
            sx={{
              color: "#FFFFFF",
              mr: "-10px",
              textTransform: "none",
              mt: "50px",
              fontSize: "16px",
              fontWeight: 500,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              maxWidth: "250px",
              alignSelf: "center",
            }}
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => {
              setStep("custom");
              setIsComplete({ isDone: false, isLoading: false });
              setPreviewData(null);
              setIsPreviewLoaded(false);
            }}
          >
            Back to Selection
          </Button>
        </Box>
      ) : (
        <Typography
          sx={{
            fontWeight: 600,
            color: "white",
            textAlign: "center",
            maxWidth: { xs: "100%", md: "55.24%" },
            alignSelf: "center",
            mt: { xs: "20%", md: "25%" },
          }}
          variant='h2'
        >
          Design a Passenger Profile and Get Personalized Ancillaries
        </Typography>
      )}
    </Box>
  );
};

export default Preview;